<form [formGroup]="newProcessForm"  #formDir="ngForm" class="form-horizontal" name="form">

    <app-loading [showLoading]="isLoading"></app-loading>
    
    <div class="po-row row" style="margin-top:15px">
        <po-widget [p-primary]="true" class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
            <div style="display: flex;align-items: center;">
                <span class="po-icon po-icon-info" style="margin-right:15px;color:blue"></span>
                <strong>{{this.literals.theFields}} <b style="color: red;">(Obrigatório)</b> {{this.literals.mustBeFilled}}</strong>
            </div>
        </po-widget>
        <div class="po-row row" style="margin-left:15px" *ngIf="textoRfqRevisao">
            <po-info
                [p-label]="textoRfqRevisao"
                >
            </po-info>
        </div>
    </div> 

    

    <!-- <app-loading *ngIf="isLoad"></app-loading> -->

    <po-modal
        p-title={{this.literals.RFQOpening}}
        p-size="xl"
        [p-hide-close]="true"
        >
        <form>
            <div class="po-row">
                <po-select
                  class="po-md-6"
                  name="checkboxGroup"
                  p-label={{this.literals.newRFQQuestion}}
                  [p-options]="optionsRevisao" 
                  (p-change)="choiseModalRfq(optionsRevisaoField)"
                  #optionsRevisaoField
                >
                </po-select>
              
                <po-combo
                 *ngIf="revisaoRFQ"
                  class="po-md-6"
                  name="motivoRevisao"
                  p-label="Motivo da Revisão?*"
                  [p-options]="optionsMotivoRevisao" 
                  (p-change)="selectClienteRfq(checkboxGroupField)"
                  #checkboxGroupField
                >
                </po-combo>
                <po-combo 
                    name="modalCliente"
                    *ngIf="revisaoRFQCliente && isRepresentante"
                    class="po-md-6"
                    p-label="Cliente*"
                    [p-options]="opstionsClientesModal"
                    [p-filter-mode]='modeFilter'
                    [p-disabled]="isLoadingInput"
                    (p-change)="selectClienteRfq(optionsClienteField)"
                    #optionsClienteField
                 ></po-combo>

                <po-combo
                    *ngIf="isProjeto"
                    class="po-md-6"
                    name="nomeProjeto"
                    p-label="Nome do Projeto*"
                    [p-options]="projects" 
                    (p-change)="selectClienteRfq(nomeProjetoField)"
                    #nomeProjetoField
                >
                </po-combo>
              </div>

              <div class="po-row" *ngIf="isDisabledBoxRevisao">
                <po-checkbox-group
                  class="po-md-12"
                  name="checkboxGroup"
                  [p-columns]="3"
                  [p-options]="optionsRevisaoModal"
                  
                >
                </po-checkbox-group>

                <po-textarea class="po-md-12" name="orderDetail" p-label="Justificativa:" [p-rows]=8>
                </po-textarea>
              </div>
        </form>

        <po-modal-footer [p-disabled-align]="false">
            <po-button  p-type="primary" p-label="Voltar" (p-click)="closeModal()"> </po-button>
            <po-button  p-type="primary" [p-disabled]="isDisabledBTmodal" p-label="Salvar" (p-click)="salvarModal()"> </po-button>
          </po-modal-footer>
    </po-modal>

        <po-widget 
            p-title="Dados do Cliente"
            *ngIf="abaDadosCliente"
        >  
            <div class="po-row row form-group">
                    
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isRepresentante">
                    <po-select 
                        name="cliente_corrente"
                        p-required="true"
                        [p-show-required]="true" 
                        (p-change)="enableOrDisabled(cliente_correnteField)"
                        formControlName="cliente_corrente"
                        p-label="Cliente Corrente"
                        (ngModel)="inputsJson.cliente_corrente"
                        [p-options]="yesOrNot"
                        #cliente_correnteField
                        [p-disabled]="isViewInput"
                    >
                    </po-select>
                    <span 
                        *ngIf="this.newProcessForm.get('cliente_corrente')?.invalid && this.newProcessForm.get('cliente_corrente')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isCodSap">
                    <po-combo 
                        (p-change)="preencherClients($event)"
                        name="cod_sap" 
                        (ngModel)="inputsJson.cod_sap"
                        formControlName="cod_sap"
                        p-label="Códido SAP"
                        [p-required]="isCodSap"
                        [p-show-required]="isCodSap"
                        [p-options]="optionsClients"
                        [p-filter-mode]='modeFilter'
                        [p-disabled]="isLoadingInput"
                        (p-input-change)="seachClient($event)"
                    >
                    </po-combo>
                    <span 
                        *ngIf="this.newProcessForm.get('cod_sap')?.invalid && this.newProcessForm.get('cod_sap')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                    
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" >
                    <po-input 
                        (p-change)="validarcnpj($event)"
                        name="cnpj" 
                        [(ngModel)]="inputsJson.cnpj"
                        formControlName="cnpj"
                        required
                        p-required="true"
                        [p-show-required]="true"
                        p-label="CNPJ"
                        p-mask="99.999.999/9999-99"
                        #cnpjField
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('cnpj')?.invalid && this.newProcessForm.get('cnpj')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="nome_empresa" 
                        required 
                        [p-maxlength]=35
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.nome_empresa"
                        formControlName="nome_empresa"
                        p-label="Nome da Empresa"
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('nome_empresa')?.invalid && this.newProcessForm.get('nome_empresa')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-combo 
                        required 
                        [p-required]="true"
                        [p-show-required]="true"
                        name="pais" 
                        (ngModel)="inputsJson.pais"
                        [p-options]="optionsPaises"
                        formControlName="pais"
                        p-label="País"
                        [p-filter-mode]='modeFilter'
                        [p-disabled]="isLoadingInput"
                    ></po-combo>
                    <span 
                        *ngIf="this.newProcessForm.get('pais')?.invalid && this.newProcessForm.get('pais')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="cep" 
                        (p-change)="findCep($event)"
                        p-mask="99999-999"
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.cep"
                        formControlName="cep"
                        p-label="CEP"
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('cep')?.invalid && this.newProcessForm.get('cep')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="estado" 
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.estado"
                        formControlName="estado"
                        p-label="Estado"
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('estado')?.invalid && this.newProcessForm.get('estado')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="cidade" 
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.cidade"
                        formControlName="cidade"
                        p-label="Cidade"
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('cidade')?.invalid && this.newProcessForm.get('cidade')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-select
                        name="segmento" 
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        (p-change)="enableOrDisabled(segmentoField)"
                        (ngModel)="inputsJson.segmento"
                        formControlName="segmento"
                        p-label="Segmento"
                        [p-options]="segmento"
                        #segmentoField
                        [p-disabled]="isViewInput"
                    >
                    </po-select>
                    <span 
                        *ngIf="this.newProcessForm.get('segmento')?.invalid &&  this.newProcessForm.get('segmento')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div> 
            
                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="nome_solic" 
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.nome_solic"
                        formControlName="nome_solic"
                        p-label="Nome Completo do Solicitante"
                        p-tooltip="Pessoa responsável pelo recebimento do orçamento."
                        p-tooltip-position="right"
                        [p-disabled]="isViewInput"
                    ></po-input>
                    <span 
                        *ngIf="this.newProcessForm.get('nome_solic')?.invalid && this.newProcessForm.get('nome_solic')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div>
                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12" >
                    <po-email 
                        name="email_solic" 
                        required 
                        p-required="true"
                        [p-show-required]="true"
                        [(ngModel)]="inputsJson.email_solic"
                        formControlName="email_solic"
                        p-label="E-mail Solicitante"
                        [p-disabled]="isViewInput"
                    ></po-email>
                    <span 
                        *ngIf="this.newProcessForm.get('email_solic')?.invalid && this.newProcessForm.get('email_solic')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                        Campo obrigatório!
                    </span>
                </div> 
            
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-combo 
                        name="ddi_solic" 
                        (ngModel)="inputsJson.ddi_solic"
                        formControlName="ddi_solic"
                        p-label="DDI Solicitante"
                        (p-change)="isMaskSolicitante($event)"
                        [p-optional]="true"
                        [p-options]="optionsDDi"
                        [p-filter-mode]='modeFilter'
                        [p-disabled]="isLoadingInput"
                    ></po-combo>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="tel_solic"  
                        [p-mask]="maskDDISolicTecnico"
                        [(ngModel)]="inputsJson.tel_solic"
                        formControlName="tel_solic"
                        p-label="Telefone Solicitante"
                        [p-optional]="true"
                        [p-disabled]="isViewInput"
                    ></po-input>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="cel_solic"  
                        [(ngModel)]="inputsJson.cel_solic"
                        formControlName="cel_solic"
                        p-label="Celular Solicitante"
                        [p-mask]="maskDDISoli"
                        [p-optional]="true"
                        [p-disabled]="isViewInput"
                    ></po-input>
                </div>
                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="nome_tec" 
                        onblur="habilitaCenarios(this)" 
                        [(ngModel)]="inputsJson.nome_tec"
                        formControlName="nome_tec"
                        p-label="Responsável técnico do cliente"
                        [p-optional]="true"
                        p-tooltip="Pessoa responsável pelo projeto a ser orçado."
                        p-tooltip-position="right"
                        [p-disabled]="isViewInput"
                    ></po-input>
                </div>
             
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-combo 
                        name="ddi_tec" 
                        (ngModel)="inputsJson.ddi_tec"
                        formControlName="ddi_tec"
                        p-label="DDI Técnico"
                        [p-optional]="true"
                        [p-options]="optionsDDi"
                        [p-filter-mode]='modeFilter'
                        [p-disabled]="isLoadingInput"
                        (p-change)="isMaskTecnico($event)"
                        [p-disabled]="isViewInput"
                    ></po-combo>
                </div>
                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="tel_tec" 
                        [(ngModel)]="inputsJson.tel_tec"
                        formControlName="tel_tec"
                        p-label="Telefone Técnico"
                        [p-optional]="true"
                        [p-mask]="maskDDITeleTecnico"
                        [p-disabled]="isViewInput"
                    ></po-input>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-input 
                        name="cel_tec" 
                        [(ngModel)]="inputsJson.cel_tec"
                        formControlName="cel_tec"
                        p-label="Celular Técnico"
                        [p-optional]="true"
                        [p-mask]="maskDDITecnico"
                        [p-disabled]="isViewInput"
                    ></po-input>
                </div>
                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                    <po-email 
                        name="email_tec" 
                        [(ngModel)]="inputsJson.email_tec"
                        formControlName="email_tec"
                        p-label="E-mail Técnico"
                        [p-optional]="true"
                        [p-disabled]="isViewInput"
                    ></po-email>
                </div>
            </div>
           

            <div style="display: flex; justify-content: end;margin-bottom: 30px;"> 
                <po-button p-icon="po-icon-arrow-right" class="round-button" (p-click)="hideOrShow('dadosCliente')"></po-button>    
            </div>
        </po-widget>
            
        <po-widget 
            p-title="Objeto da Proposta"
            *ngIf="abaObjetoProposta"
        >
                <div class="po-row">
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-input 
                            name="nome_projeto"  
                            (p-change)="addProposta($event)"
                            [p-maxlength]="40" 
                            required
                            p-required="true"
                            [p-show-required]="true" 
                            [(ngModel)]="inputsJson.nome_projeto"
                            formControlName="nome_projeto"
                            p-label="Nome do Projeto"
                            [p-disabled]="isViewInput"
                        ></po-input>
                        <span 
                            *ngIf="this.newProcessForm.get('nome_projeto')?.invalid && this.newProcessForm.get('nome_projeto')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-select
                            name="tipo_venda" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (p-change)="enableOrDisabled(tipo_vendaField)"
                            (ngModel)="inputsJson.tipo_venda"
                            formControlName="tipo_venda"
                            p-label="Tipo de Venda"
                            [p-options]=tpVenda
                            #tipo_vendaField
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                            *ngIf="this.newProcessForm.get('tipo_venda')?.invalid && this.newProcessForm.get('tipo_venda')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-select
                            name="idioma_proposta" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.idioma_proposta"
                            formControlName="idioma_proposta"
                            p-label="Idioma da Proposta"
                            [p-options]=idioma
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                            *ngIf="this.newProcessForm.get('idioma_proposta')?.invalid && this.newProcessForm.get('idioma_proposta')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                        <po-select
                            name="moeda" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.moeda"
                            formControlName="moeda"
                            p-label="Moeda da Proposta"
                            [p-options]=moeda
                            [p-disabled]="isViewInput"
                        >
                            
                        </po-select>
                        <span 
                            *ngIf="this.newProcessForm.get('moeda')?.invalid && this.newProcessForm.get('moeda')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                        
                        <po-select
                            name="neg_prev_forn" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (p-change)="enableOrDisabledlocal(neg_prev_fornField)"
                            (ngModel)="inputsJson.neg_prev_forn"
                            formControlName="neg_prev_forn"
                            p-label="Componentes pré-negociados"
                            [p-options]="yesOrNot"
                            p-tooltip="Caso tenha algum componente/partes do projeto, com negociação entre o fornecedor e o cliente colocar 'sim' e 
                            indicar logo após qual/quais o(s) componente(s)."
                            p-tooltip-position="right"
                            #neg_prev_fornField
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                            *ngIf="this.newProcessForm.get('neg_prev_forn')?.invalid && this.newProcessForm.get('neg_prev_forn')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-select 
                            name="tipo_negocio" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (p-change)="enableOrDisabledlocal(tipo_negocioField)"
                            (ngModel)="inputsJson.tipo_negocio"
                            formControlName="tipo_negocio"
                            p-label="Tipo de Negócio"
                            [p-options]="tipoNegocio"   
                            #tipo_negocioField
                            [p-disabled]="isViewInput"
                        >                                
                        </po-select>
                        <span 
                            *ngIf="this.newProcessForm.get('tipo_negocio')?.invalid && this.newProcessForm.get('tipo_negocio')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                    </div>
                </div>

                
                
                <section style="display: grid;">
                    <ng-container *ngIf="proposta.controls.length > 0">
                        <span><b>Produto</b></span>
                        <po-divider></po-divider>
                    </ng-container>
                    
 
                        <div formArrayName="proposta">
                            <div *ngFor="let control of proposta.controls; let i = index" [formGroupName]="i">
                                <po-button
                                    *ngIf="!isViewInput"
                                    (click)="deleteProposta(i)"
                                    p-icon="po-icon-delete"
                                    style="cursor: pointer;"
                                    p-size="large"
                                    p-label="Excluir Item"
                                >
                                </po-button>
                                
                                <div class="po-row">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isProdCorrent">
                                        <po-select 
                                            name="produto_corrente" 
                                            formControlName="produto_corrente"
                                            (p-change)="enableOrDisabled(produto_correnteField)"
                                            p-label="Produto Corrente"
                                            [p-options]="yesOrNot"
                                            #produto_correnteField
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isCodProdCorrent"> 
                                        <po-combo  
                                            p-placeholder="código do produto" 
                                            name="cod_produto" 
                                            formControlName="cod_produto"
                                            (p-change)="preencherInfosProduto($event)"
                                            p-label="Código Produto (Hi-Mix)"
                                            [p-options]="optionsMaterias"
                                            [p-filter-mode]='modeFilter'
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-combo>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                                        <po-input 
                                            name="ncm" 
                                            formControlName="ncm"
                                            p-placeholder="NCM" 
                                            [p-maxlength]="16"
                                            p-label="NCM"
                                            p-mask="9999.99.99"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-3 po-lg-4 po-md-4 po-sm-12">
                                        <po-input 
                                            name="mod_pn_prod" 
                                            formControlName="mod_pn_prod"
                                            p-placeholder="Modelo ou PN" 
                                            [p-maxlength]="40"
                                            p-label="Modelo ou Part Number do Produto"
                                            (p-change)="addProductDocuments($event)"
                                            [p-disabled]="isViewInput"  
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="apli_prod" 
                                            formControlName="apli_prod"
                                            p-placeholder="aplicação do produto" 
                                            [p-maxlength]="20"
                                            p-label="Aplicação do Produto"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="func_prod" 
                                            formControlName="func_prod"
                                            p-placeholder="função do produto" 
                                            [p-maxlength]="20"
                                            p-label="Função do Produto"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="moeda_target_price" 
                                            formControlName="moeda_target_price"
                                            [p-options]="moeda"
                                            p-label="Moeda do Target Price"
                                            p-mask="999.999.999,99"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="target_price" 
                                            formControlName="target_price"
                                            p-placeholder="target price" 
                                            [p-maxlength]="11"
                                            p-label="Target Price"
                                            (keyup)="formatarValor($event,2)" 
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input
                                            name="qtde_consumo1" 
                                            formControlName="qtde_consumo1"
                                            p-placeholder="quantidade cenário 1" 
                                            p-label="Cenário 1"
                                            [p-maxlength]="11"
                                            [p-disabled]="isViewInput"
                                            (keyup)="formatarValor($event,0)" 
                                        ></po-input>
                                    </div>
                                </div>
                                <div class="po-row row form-group"> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="periodo_consumo1" 
                                            formControlName="periodo_consumo1"
                                            p-label="Período de Consumo 1"
                                            (p-change)="enableOrDisabledlocal(periodo_consumo1Field)"
                                            [p-options]="periodoConsumo"
                                            #periodo_consumo1Field
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="tipo_producao1" 
                                            formControlName="tipo_producao1"
                                            p-label="Tipo de Produção 1"
                                            [p-options]="tipoProducao1"
                                            [p-disabled]="isViewInput"
                                            #tipo_producao1Field
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isProgramEntrega1">
                                        <po-select 
                                            name="program_entrega1" 
                                            formControlName="program_entrega1"
                                            p-label="Programação de Entrega 1"
                                            [p-options]="programEntrega"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-datepicker
                                            name="prazo_entreg_consumo1" 
                                            formControlName="prazo_entreg_consumo1"
                                            p-label="Prazo de Entrega Desejado 1"
                                            [p-disabled]="isViewInput"
                                        ></po-datepicker>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="qtde_consumo2" 
                                            formControlName="qtde_consumo2"
                                            p-placeholder="quantidade cenário 2" 
                                            (p-change)="enableOrDisabledlocal(qtde_consumo2Field)"
                                            p-label="Cenário 2"
                                            [p-maxlength]="11"
                                            (keyup)="formatarValor($event,0)" 
                                            #qtde_consumo2Field
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isCenario2">
                                        <po-select 
                                            name="periodo_consumo2" 
                                            formControlName="periodo_consumo2"
                                            (p-change)="enableOrDisabledlocal(periodo_consumo2Field)"
                                            [p-options]="periodoConsumo"
                                            p-label="Período de Consumo 2"
                                            #periodo_consumo2Field
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isCenario2">
                                        <po-select 
                                            name="tipo_producao2" 
                                            formControlName="tipo_producao2"
                                            p-label="Tipo de Produção 2"
                                            [p-options]="tipoProducao2"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isProgramEntrega2">
                                        <po-select 
                                            name="program_entrega2" 
                                            formControlName="program_entrega2"
                                            p-label="Programação de Entrega 2"
                                            [p-options]="programEntrega"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isCenario2">
                                        <po-datepicker 
                                            name="prazo_entreg_consumo2" 
                                            formControlName="prazo_entreg_consumo2"
                                            p-label="Prazo de Entrega Desejado 2"
                                            [p-disabled]="isViewInput"
                                        ></po-datepicker>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="qtde_consumo3" 
                                            formControlName="qtde_consumo3"
                                            p-placeholder="quantidade cenário 3" 
                                            (p-change)="enableOrDisabledlocal(qtde_consumo3Field)"
                                            p-label="Cenário 3"
                                            [p-maxlength]="11"
                                            #qtde_consumo3Field
                                            (keyup)="formatarValor($event,0)" 
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isCenario3">
                                        <po-select 
                                            name="periodo_consumo3" 
                                            formControlName="periodo_consumo3"
                                            (p-change)="enableOrDisabledlocal(periodo_consumo3Field)"
                                            p-label="Período de Consumo 3"
                                            [p-options]="periodoConsumo"
                                            #periodo_consumo3Field 
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isCenario3">
                                        <po-select 
                                            name="tipo_producao3" 
                                            formControlName="tipo_producao3"
                                            p-label="Tipo de Produção 3"
                                            [p-options]="tipoProducao3"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isProgramEntrega3">
                                        <po-select 
                                            name="program_entrega3" 
                                            formControlName="program_entrega3"
                                            p-label="Programação de Entrega 3"
                                            [p-options]="programEntrega"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isCenario3">
                                        <po-datepicker 
                                            name="prazo_entreg_consumo3" 
                                            formControlName="prazo_entreg_consumo3"
                                            p-label="Prazo de Entrega Desejado 3"
                                            [p-disabled]="isViewInput"
                                        ></po-datepicker>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="qtde_consumo4" 
                                            formControlName="qtde_consumo4"
                                            (p-change)="enableOrDisabledlocal(qtde_consumo4Field)"
                                            p-placeholder="quantidade cenário 4" 
                                            p-label="Cenário 4"
                                            [p-maxlength]="11"
                                            #qtde_consumo4Field
                                            (keyup)="formatarValor($event,0)" 
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                </div>
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isCenario4">
                                        <po-select 
                                            name="periodo_consumo4" 
                                            formControlName="periodo_consumo4"
                                            (p-change)="enableOrDisabledlocal(periodo_consumo4Field)"
                                            p-label="Período de Consumo 4"
                                            [p-options]="periodoConsumo"
                                            #periodo_consumo4Field
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isCenario4">
                                        <po-select 
                                            name="tipo_producao4" 
                                            formControlName="tipo_producao4"
                                            p-label="Tipo de Produção 4"
                                            [p-options]="tipoProducao4"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isProgramEntrega4">
                                        <po-select 
                                            name="program_entrega4" 
                                            formControlName="program_entrega4"
                                            p-label="Programação de Entrega 4"
                                            [p-options]="programEntrega"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12"  *ngIf="isCenario4">
                                        <po-datepicker 
                                            name="prazo_entreg_consumo4" 
                                            formControlName="prazo_entreg_consumo4"
                                            p-label="Prazo de Entrega Desejado 4"
                                            [p-disabled]="isViewInput"
                                        ></po-datepicker>
                                    </div>
                                </div>
                                
                                <po-divider></po-divider>
                            
                                <div class="po-row row form-group">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="simi_prod" 
                                            formControlName="simi_prod"
                                            p-label="Similiaridade com Produtos"
                                            [p-options]="yesOrNot"
                                            (p-change)="enableOrDisabledlocal(simi_prodField)"
                                            #simi_prodField
                                            [p-disabled]="isViewInput"
                                        >
                                    
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isindicProd">
                                        <po-combo 
                                            name="indic_prod" 
                                            formControlName="indic_prod"
                                            p-placeholder="Indicar produto" 
                                            p-label="Indicar Produto"
                                            [p-disabled]="isViewInput"
                                            [p-options]="optionsMaterias"
                                        ></po-combo>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="aceit_altern" 
                                            formControlName="aceit_altern"
                                            p-label="Aceita Alternativos"
                                            [p-options]="yesOrNot"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-2 po-lg-2 po-md-3 po-sm-12 ">
                                        <po-select 
                                            name="finalidade_prod" 
                                            formControlName="finalidade_prod"
                                            p-label="Finalidade do Uso do Produto"
                                            [p-options]="finalidadeProd"  
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isPpb">
                                        <po-select 
                                            name="possui_ppb" 
                                            formControlName="possui_ppb"
                                            p-label="Cliente Possui PPB?"
                                            [p-options]="yesOrNot"
                                            [p-disabled]="isViewInput"
                                            >
                                        </po-select>
                                    </div>
                                    <div [ngClass]="isSumaziProdQual ? 'po-xl-2 po-lg-2 po-md-2 po-sm-12' : 'po-xl-3 po-lg-3 po-md-3 po-sm-12'" *ngIf="isIsencaoImpost">
                                        <po-select 
                                            name="isencao_impost" 
                                            formControlName="isencao_impost"
                                            (p-change)="enableOrDisabledlocal(isencao_impostField)"
                                            #isencao_impostField
                                            p-label="Benefícios Fiscais"
                                            [p-options]="isencaoImpost"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 "  *ngIf="isReqCri">
                                        <po-input 
                                            name="req_cri_espec" 
                                            formControlName="req_cri_espec"
                                            p-label="Requisitos Críticos Especiais"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>										
                                    <div [ngClass]="isSumaziProdQual ? 'po-xl-2 po-lg-2 po-md-2 po-sm-12' : 'po-xl-3 po-lg-3 po-md-3 po-sm-12'">
                                        <po-select 
                                            name="sumazi_prod" 
                                            formControlName="sumazi_prod"
                                            p-label="Produção Unificada"
                                            [p-options]="yesOrNot"
                                            (p-change)="enableOrDisabledlocal(sumazi_prodField)"
                                            #sumazi_prodField
                                            p-tooltip="Condicionado a venda casada de duas ou mais placas do projeto."
                                            p-tooltip-position="right"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div [ngClass]="isSumaziProdQual ? 'po-xl-2 po-lg-2 po-md-2 po-sm-12' : 'po-xl-3 po-lg-3 po-md-3 po-sm-12'"
                                        *ngIf="isSumaziProdQual">
                                        <po-input 
                                            name="sumazi_prod_qual" 
                                            formControlName="sumazi_prod_qual"
                                            [p-maxlength]="70"
                                            p-label="Produtos sumarizados"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  *ngIf="isObsIsencaoImpost">
                                        <po-textarea 
                                            name="obs_isencao_impost" 
                                            formControlName="obs_isencao_impost"
                                            [p-rows]="3" 
                                            p-label="Informações sobre impostos e legislações"
                                            [p-disabled]="isViewInput"
                                        ></po-textarea>
                                        <po-upload 
                                            p-type="primary"
                                            class="large-button"
                                            p-aria-label="string"
                                            p-label="Anexar informações"
                                            [p-auto-upload]="true"
                                            (p-upload)="onFileSelected($event)"
                                            p-url="https://po-sample-api.fly.dev/v1/uploads/addFile"
                                      >
                                      </po-upload>
                                    </div>
                                </div> 
                            
                                <po-divider></po-divider>
                            
                                <div class="po-row row form-group" style="margin-bottom: 15px;">
                                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                                        <span><b>Requisitos Técnicos / Especiais</b></span>
                                    </div>
                                </div> 
                            
                                <div class="po-row row form-group">
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                                        <po-select 
                                            name="espec_solda" 
                                            formControlName="espec_solda"
                                            p-label="Especificações da Soldabilidade"
                                            [p-disabled]="isViewInput"
                                            [p-options]="especSolda"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="ipca" 
                                            formControlName="ipca"
                                            [p-options]="ipcaOptions"
                                            p-label="Montagem Norma IPC-A-610"
                                            p-tooltip="Classe 1 - Indicado para produtos de consumo, como linha branca, por exemplo. Classe 2 - Indicado para produtos em geral. Classe mais utilizada atualmente na Hi-mix  Classe 3 - Indicado para produtos de alto desempenho. Linhas médicas, automotivas e aeroespaciais."
                                            p-tooltip-position="right"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-input 
                                            name="certif_exter" 
                                            formControlName="certif_exter"
                                            [p-maxlength]="70"
                                            p-label="Certificação ou Qualificação Externa"
                                            [p-disabled]="isViewInput"
                                        ></po-input>
                                    </div>
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="verniz_resi" 
                                            formControlName="verniz_resi"
                                            [p-options]="yesOrNot"
                                            p-label="Necessidade Verniz ou Resina?"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="equi_fw_teste" 
                                            formControlName="equi_fw_teste"
                                            [p-options]="yesOrNot"
                                            p-label="Será fornecido equip. para FW e teste?"
                                            [p-disabled]="isViewInput"
                                            >
                                        </po-select>
                                    </div>
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="cobra_nre" 
                                            formControlName="cobra_nre"
                                            p-label="Cobranças NREs"
                                            [p-options]="cobraNre"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                                        <po-select 
                                            name="jigas_cliente" 
                                            formControlName="jigas_cliente"
                                            p-label="Jigas de Teste Fornecidas Pelo Cliente?"
                                            [p-options]="yesOrNot"
                                            [p-disabled]="isViewInput"
                                        >
                                        </po-select>
                                    </div> 
                                </div> 
                               <div class="po-row row form-group">
                                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                        <po-textarea 
                                            name="obs_obj_proposta" 
                                            formControlName="obs_obj_proposta"
                                            [p-rows]="4" 
                                            p-placeholder="Descreva aqui qualquer observação que seja relevante para a cotação."
                                            [p-maxlength]="100"
                                            p-label="Observação"
                                            [p-disabled]="isViewInput"
                                        ></po-textarea>
                                    </div>
                                </div> 
                              
                            </div>
                          </div>
                    
                          <div class="po-row row" style="display: flex; justify-content: end;margin-top:15px">
                            <po-button 
                                *ngIf="!isViewInput"
                                p-type="primary"  
                                class="large-button" 
                                p-label="Adicionar Produtos"
                                (click)="addProposta()">
                            </po-button>
                        </div>
                        
                
                <!-- <div *ngIf="camposNegociacao.length > 0">
                    
                    
                    
                        
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">

                        <div *ngFor="let campo of camposNegociacao;">
                            <po-divider></po-divider>
                            
                            <po-
                            <app-proposta [index]="camposNegociacao.length" [value]="campo"></app-proposta>
                            
                        </div>
                    </div>
                    
                </div> 
                 -->
                <div style="display: flex; justify-content: space-between;margin-bottom: 80px;">
                    <po-button p-icon="po-icon-arrow-left" class="round-button"  (p-click)="hideOrShow('objetoPropostaVolta')"></po-button>    
                    <po-button p-icon="po-icon-arrow-right" class="round-button" (p-click)="hideOrShow('objetoProposta')"></po-button> 
                </div>
            </section>
                
        </po-widget> 
            
        <po-widget 
            p-title="Cenário de Negócio"
            *ngIf="abaCenarioNegocio"
        >
                <div class="po-row row">
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-combo  
                            required 
                            [p-required]="true"
                            [p-show-required]="true" 
                            name="pais_fatura" 
                            (p-change)="howPais($event,'pais_fatura')"
                            (ngModel)="inputsJson.pais_fatura"
                            formControlName="pais_fatura"
                            p-label="País para Faturamento"
                            [p-options]="optionsPaises"
                            [p-disabled]="isLoadingInput"
                            [p-filter-mode]='modeFilter'
                            [p-disabled]="isViewInput"
                        ></po-combo>
                            <span 
                                *ngIf="this.newProcessForm.get('pais_fatura')?.invalid && this.newProcessForm.get('pais_fatura')?.touched"
                                style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                    Campo obrigatório!
                            </span>
                            
                        </div>
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isBrasil">
                            <po-combo   
                                name="uf_fatura_br" 
                                (ngModel)="inputsJson.uf_fatura_br"
                                formControlName="uf_fatura_br"  
                                p-label="Estado para Faturamento"  
                                [p-options]="estados"
                                [p-disabled]="isViewInput"
                                [p-filter-mode]='modeFilter'
                            ></po-combo>
                        </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isStates">
                        <po-input 
                            name="uf_fatura"  
                            [p-maxlength]="25" 
                            [(ngModel)]="inputsJson.uf_fatura"
                            formControlName="uf_fatura"    
                            p-label="Estado para Faturamento"
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                        <po-select
                            name="autoriza_divulg" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.autoriza_divulg"
                            formControlName="autoriza_divulg" 
                            p-label="Autoriza divulgação para fornecedores?"   
                            [p-options]="yesOrNot"
                            p-tooltip="Para cotação dos componentes é necessário abrir qual é o projeto e o cliente final, afim de conseguir preços negociados!"
                            p-tooltip-position="right"
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                                *ngIf="this.newProcessForm.get('autoriza_divulg')?.invalid && this.newProcessForm.get('autoriza_divulg')?.touched"
                                style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                    Campo obrigatório!
                            </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                        <po-select 
                            name="period_contrato" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (p-change)="enableOrDisabledlocal(period_contratoField)"
                            (ngModel)="inputsJson.period_contrato"
                            formControlName="period_contrato"  
                            p-label="Período de Contrato"
                            [p-options]="periodContrato"
                            #period_contratoField
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                        *ngIf="this.newProcessForm.get('period_contrato')?.invalid && this.newProcessForm.get('period_contrato')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isPeriodoContrato">
                        <po-input 
                            name="outro_periodo"  
                            [p-maxlength]="8" 
                            [(ngModel)]="inputsJson.outro_periodo"
                            formControlName="outro_periodo"  
                            p-label="Outro Período de Contrato (meses)"
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="false">
                        <po-select 
                            name="prazo_pgto" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.prazo_pgto"
                            (p-change)="enableOrDisabledlocal(prazo_pgtoField)"
                            formControlName="prazo_pgto"
                            p-label="Prazo de Pagamento"
                            [p-options]="prazoPgto"   
                            #prazo_pgtoField
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                        *ngIf="this.newProcessForm.get('prazo_pgto')?.invalid && this.newProcessForm.get('prazo_pgto')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="isOutroPrazo">
                        <po-input 
                            name="outro_prazo_pgto"  
                            [p-maxlength]="40" 
                            [(ngModel)]="inputsJson.outro_prazo_pgto"
                            formControlName="outro_prazo_pgto"
                            p-label="Outro Prazo de Pagamento"
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12" *ngIf="!isNacional">
                        <po-select 
                            name="condi_entrega"  
                            (ngModel)="inputsJson.condi_entrega"
                            formControlName="condi_entrega"
                            p-label="Condição de Entrega"
                            [p-options]="condiEntrega"
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                    </div>
                </div>
                <div class="po-row row">
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <po-textarea 
                            name="obs_cenario" 
                            cols="30" 
                            [p-rows]="5" 
                            p-placeholder="Descreva aqui qualquer informação pertinente ao cenário de negócio."  
                            maxlength="240"
                            [(ngModel)]="inputsJson.obs_cenario"
                            formControlName="obs_cenario"
                            p-label="Observação"
                            [p-disabled]="isViewInput"
                        ></po-textarea>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;margin-bottom: 80px;">
                    <po-button p-icon="po-icon-arrow-left" class="round-button"  (p-click)="hideOrShow('cenarioNegocioVolta')"></po-button>    
                    <po-button p-icon="po-icon-arrow-right" class="round-button" (p-click)="hideOrShow('cenarioNegocio')"></po-button> 
                </div>
        </po-widget>
          
            
        <po-widget *ngIf="abaDadosAdicionais" p-title="Dados Adicionais" >
            <po-widget *ngIf="isIsencaoImpost"  p-title="Exportação">
                    <div class="po-row">
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                            <po-combo  
                                required 
                                [p-required]="true"
                                [p-show-required]="true" 
                                (p-change)="howPais($event,'pais_destino')"
                                name="pais_destino" 
                                (ngModel)="inputsJson.pais_destino"
                                formControlName="pais_destino"
                                p-label="País Destino"
                                [p-options]="optionsPaisesExportacao"
                                [p-disabled]="isLoadingInput"
                                [p-filter-mode]='modeFilter'
                                [p-disabled]="isViewInput"
                            ></po-combo>
                            <span 
                            *ngIf="this.newProcessForm.get('pais_destino')?.invalid && this.newProcessForm.get('pais_destino')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                        </div>
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                            <po-input 
                                *ngIf="!isEstados"
                                name="estado_destino" 
                                required 
                                p-required="true"
                                [p-show-required]="true"  
                                [(ngModel)]="inputsJson.estado_destino"
                                formControlName="estado_destino"
                                p-label="Estado Destino"
                                [p-disabled]="isViewInput"
                            ></po-input>

                            <po-combo   
                                *ngIf="isEstados"
                                name="estado_destino" 
                                (ngModel)="inputsJson.estado_destino"
                                formControlName="estado_destino"  
                                p-label="Estado Destino"  
                                [p-required]="true"
                                [p-show-required]="true" 
                                [p-options]="estadosDestino"
                                [p-disabled]="isViewInput"
                                [p-filter-mode]='modeFilter'
                            ></po-combo>
                            <span 
                            *ngIf="this.newProcessForm.get('estado_destino')?.invalid && this.newProcessForm.get('estado_destino')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                        </div>
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                            <po-select 
                                name="incoterm_exportacao" 
                                required
                                p-required="true"
                                [p-show-required]="true"   
                                (ngModel)="inputsJson.incoterm_exportacao"
                                formControlName="incoterm_exportacao"
                                p-label="Incoterm"
                                [p-options]="incotermoptions"
                                [p-disabled]="isViewInput"
                            >
                            </po-select>
                            <span 
                            *ngIf="this.newProcessForm.get('incoterm_exportacao')?.invalid && this.newProcessForm.get('incoterm_exportacao')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                        </div>
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                            <po-select
                                name="certif_origem" 
                                required 
                                p-required="true"
                                [p-show-required]="true"  
                                (ngModel)="inputsJson.certif_origem"
                                formControlName="certif_origem"
                                p-label="Certificado de Origem"
                                [p-options]="yesOrNot"
                                [p-disabled]="isViewInput"
                            >
                            </po-select>
                            <span 
                            *ngIf="this.newProcessForm.get('certif_origem')?.invalid && this.newProcessForm.get('certif_origem')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                        </div>
                        <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                            <po-select 
                                name="seguro_exportacao" 
                                required 
                                p-required="true"
                                [p-show-required]="true"  
                                (ngModel)="inputsJson.seguro_exportacao"
                                formControlName="seguro_exportacao"
                                p-label="Seguro"
                                [p-options]="seguroExportacao"
                                [p-disabled]="isViewInput"
                            >   
                            </po-select>
                            <span 
                            *ngIf="this.newProcessForm.get('seguro_exportacao')?.invalid && this.newProcessForm.get('seguro_exportacao')?.touched"
                            style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                                Campo obrigatório!
                        </span>
                        </div>
                    
                        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                            <po-textarea 
                                name="obs_exportacao" 
                                cols="30" 
                                [p-rows]="5" 
                                p-placeholder="Descreva aqui qualquer informação que seja pertinente a exportação."  
                                maxlength="100"
                                [(ngModel)]="inputsJson.obs_exportacao"
                                formControlName="obs_exportacao"
                                p-label="Observação"
                                [p-disabled]="isViewInput"
                            ></po-textarea>
                        </div>
                    </div>
            </po-widget>

            <po-widget  *ngIf="isCkdSkd" p-title="Consolidação no Fornecedor Indicado (CKD/SKD)">
                <div class="po-row row">
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                        <po-input 
                            name="prazo_pgto_fornec" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            [p-maxlength]="4" 
                            [(ngModel)]="inputsJson.prazo_pgto_fornec"
                            formControlName="prazo_pgto_fornec"
                            p-label="Prazo Pagamento Fornecedor"
                            [p-disabled]="isViewInput"
                        ></po-input>
                        <span 
                        *ngIf="this.newProcessForm.get('prazo_pgto_fornec')?.invalid && this.newProcessForm.get('prazo_pgto_fornec')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                        <po-select 
                            name="moeda_invo_bom" 
                            required 
                            p-required="true"
                            [p-show-required]="true"  
                            (ngModel)="inputsJson.moeda_invo_bom"
                            formControlName="moeda_invo_bom"
                            p-label="Moeda da invoice ou da BOM"
                            
                            [p-options]="moeda"
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                        *ngIf="this.newProcessForm.get('moeda_invo_bom')?.invalid && this.newProcessForm.get('moeda_invo_bom')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                        <po-input 
                            name="valor_invo_bom" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            [(ngModel)]="inputsJson.valor_invo_bom"
                            formControlName="valor_invo_bom"
                            p-label="Valor da Invoice ou da BOM"
                            [p-maxlength]="11" 
                            [p-disabled]="isViewInput"
                            (keyup)="formatarValor($event,2)" 
                        ></po-input>
                        <span 
                        *ngIf="this.newProcessForm.get('valor_invo_bom')?.invalid && this.newProcessForm.get('valor_invo_bom')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                        <po-select 
                            name="incoterm" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.incoterm"
                            formControlName="incoterm"
                            p-label="Incoterm"
                            [p-options]="incotermoptions"
                            [p-disabled]="isViewInput"
                        >
                        </po-select>
                        <span 
                        *ngIf="this.newProcessForm.get('incoterm')?.invalid && this.newProcessForm.get('incoterm')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                        <po-combo  
                            required 
                            [p-required]="true"
                            [p-show-required]="true" 
                            name="pais_origem_fornec" 
                            (ngModel)="inputsJson.pais_origem_fornec"
                            formControlName="pais_origem_fornec"
                            p-label="País de Origem"
                            [p-options]="optionsPaises"
                            [p-filter-mode]='modeFilter'
                            [p-disabled]="isLoadingInput"
                            [p-disabled]="isViewInput"
                        ></po-combo>
                        <span 
                        *ngIf="this.newProcessForm.get('pais_origem_fornec')?.invalid && this.newProcessForm.get('pais_origem_fornec')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                        <po-input 
                            name="qtde_envio"  
                            [p-maxlength]="11" 
                            [(ngModel)]="inputsJson.qtde_envio"
                            formControlName="qtde_envio"
                            p-label="MOC do CKD/SKD"
                            [p-optional]="true"
                            (keyup)="formatarValor($event,0)" 
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                        <po-input 
                            name="peso_envio"  
                            [(ngModel)]="inputsJson.peso_envio"
                            formControlName="peso_envio"
                            p-label="Peso (Kg)"
                            [p-maxlength]="14" 
                            [p-optional]="true"
                            (keyup)="formatarValor($event,2)" 
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 ">
                        <po-input 
                            name="cubagem_envio"  
                            [(ngModel)]="inputsJson.cubagem_envio"
                            formControlName="cubagem_envio"
                            p-label="Cubagem (m³)"
                            [p-optional]="true"
                            (keyup)="formatarValor($event,2)" 
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                
                    <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12 ">
                        <po-select 
                            name="tratativa_import" 
                            required  
                            p-required="true"
                            [p-show-required]="true" 
                            (ngModel)="inputsJson.tratativa_import"
                            formControlName="tratativa_import"
                            p-label="Tratativa Especial de Importação"
                            [p-options]="tratativaImport"
                            (p-change)="enableOrDisabledlocal(tratativa_importField)"
                            #tratativa_importField
                            [p-disabled]="isViewInput"
                        > 
                        </po-select>
                        <span 
                        *ngIf="this.newProcessForm.get('tratativa_import')?.invalid && this.newProcessForm.get('tratativa_import')?.touched"
                        style="bottom: -20px; color: red; font-size: 12px;font-weight:bold">
                            Campo obrigatório!
                    </span>
                    </div>
                    <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12 " *ngIf="isQual">
                        <po-input 
                            name="qual"  
                            [(ngModel)]="inputsJson.qual"
                            formControlName="qual"
                            p-label="Qual?"
                            [p-disabled]="isViewInput"
                        ></po-input>
                    </div>
                </div>
                <div class="po-row row">
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <po-textarea 
                            name="obs_consolid_fornec_indicado" 
                            cols="30" 
                            [p-rows]="5" 
                            p-placeholder="Descreva aqui qualquer informação que seja pertinente ao fornecedor indicado."  
                            maxlength="240"
                            [(ngModel)]="inputsJson.obs_consolid_fornec_indicado"
                            formControlName="obs_consolid_fornec_indicado"
                            p-label="Observação"
                            [p-disabled]="isViewInput"
                        ></po-textarea>
                    </div>
                </div>
            </po-widget>

            <po-widget *ngIf="isNegociacaoPre" p-title="Negociação Prévia com Fornecedor">
                <po-divider></po-divider>
                <div formArrayName="negocicao">
                    <div *ngFor="let control of negocicao.controls; let i = index">
                        <ng-container [formGroupName]="i" >
                            <div class="po-row">
                                <po-button
                                    *ngIf="!isViewInput"
                                    (click)="deleteNegocicacao(i)"
                                    p-icon="po-icon-delete"
                                    style="cursor: pointer;"
                                    p-size="large"
                                    p-label="Excluir Item"
                                >
                                </po-button>
                            </div>
                            
                            <div class="po-row">
                                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                                    <po-input 
                                        name="pn_negciado" 
                                        formControlName="pn_negciado"  
                                        onblur="validarPN()" 
                                        [p-maxlength]="40"
                                        p-label="Part Number"
                                        [p-disabled]="isViewInput"
                                    ></po-input>
                                </div>
                                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                                    <po-input 
                                        name="pn_fabricante" 
                                        formControlName="pn_fabricante"  
                                        [p-maxlength]="15"
                                        p-label="Fabricante"
                                        [p-disabled]="isViewInput"
                                    ></po-input>
                                </div>
                        
                                <div class="po-xl-2 po-lg-3 po-md-4 po-sm-12">
                                    <po-input 
                                        name="descr_pn_negociado" 
                                        formControlName="descr_pn_negociado"  
                                        [p-maxlength]="40"
                                        p-label="Descrição do Componente"
                                        [p-disabled]="isViewInput"
                                    ></po-input>
                                </div>
                                
                                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                                    <po-select 
                                        name="moeda_pn_negociado" 
                                        formControlName="moeda_pn_negociado" 
                                        p-label="Moeda do item negociado"
                                        [p-options]="moeda"
                                        [p-disabled]="isViewInput"
                                    >
                                    </po-select>
                                </div>
                                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                                    <po-input 
                                        name="custo_pn_negociado" 
                                        formControlName="custo_pn_negociado"  
                                        [p-maxlength]="16"
                                        p-label="Custo Item"
                                        [p-disabled]="isViewInput"
                                        #custo_pn_negociadoField
                                    ></po-input>
                                </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </div>
               

                <section class="po-row" style="display: grid;justify-content: end;align-items: center;margin-top: 20px;">
                    <div>
                        <po-button 
                                *ngIf="!isViewInput"
                                p-type="primary"  
                                class="large-button" 
                                p-label="Adicionar Produtos"
                                (click)="addNegocicao()">
                            </po-button>
                    </div>
                    <div>
                        <po-upload 
                            *ngIf="!isViewInput"
                            class="btn btn-default" 
                            p-label="Anexar informações"
                            [p-auto-upload]="true"
                            p-url="https://po-sample-api.fly.dev/v1/uploads/addFile" 
                            (p-upload)="onFileSelected($event)"
                        >
                        </po-upload>
                    </div>
                </section>  
                
                <div class="po-row row">
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12 flex right" >
                    
                    </div>
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <po-textarea 
                            name="obs_neg_prev_fornc" 
                            cols="30" 
                            [p-rows]="5" 
                            p-placeholder="Descreva aqui qualquer informação pertinente a negociação prévia com o fornecedor."  
                            maxlength="240"
                            [(ngModel)]="inputsJson.obs_neg_prev_fornc"
                            formControlName="obs_neg_prev_fornc"
                            p-label="Observação"
                            [p-disabled]="isViewInput"
                        ></po-textarea>
                    </div>
                </div>
                <div>
                    <!-- <po-button p-icon="po-icon-arrow-left" class="round-button"  (p-click)="stepper.previous()"></po-button>    
                    <po-button p-icon="po-icon-arrow-right" class="round-button" (p-click)="stepper.next()"></po-button>     -->
                </div>
            </po-widget>

            <div style="display: flex; justify-content: space-between;margin-bottom: 80px;">
                <po-button p-icon="po-icon-arrow-left" class="round-button"  (p-click)="hideOrShow('dadosAdicionaisVolta')"></po-button>    
                <po-button p-icon="po-icon-arrow-right" class="round-button" (p-click)="hideOrShow('dadosAdicionais')"></po-button> 
            </div>
        </po-widget>
            
           
            
        <po-widget p-title="Documentos" *ngIf="abaDocumentos">
        
            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" style="display: grid; padding-top:15px; padding-bottom: 5px;">
                <div formArrayName="documentos">
                    <div *ngFor="let control of documentos.controls; let i = index">
                        <ng-container [formGroupName]="i" class="po-row">
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <div class="po-xl-3 po-lg-3 po-md-4 po-sm-12">
                                    <po-input 
                                        name="produto_documentos" 
                                        formControlName="produto_documentos"  
                                        [p-maxlength]="11"
                                        p-label="Produto"
                                        p-disabled="true"
                                    ></po-input>
                                </div>

                                <po-divider>
                                </po-divider>
                        
                                <div class="row">
                                    <h3>Documentos Essenciais</h3>
                                </div>
                        
                                <po-checkbox-group
                                    name="doc_obri_cb1"
                                    formControlName="doc_obri_cb1"  
                                    [ngModel]="doc_obri_cb1"
                                    [p-options]="options_doc_obri_cb1"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb2"
                                    formControlName="doc_obri_cb2"  
                                    [ngModel]="doc_obri_cb2"
                                    [p-options]="options_doc_obri_cb2"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb3"
                                    formControlName="doc_obri_cb3"  
                                    [ngModel]="doc_obri_cb3"
                                    [p-options]="options_doc_obri_cb13"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb4"
                                    formControlName="doc_obri_cb4"  
                                    [ngModel]="doc_obri_cb4"
                                    [p-options]="options_doc_obri_cb4"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb5"
                                    formControlName="doc_obri_cb5"  
                                    [ngModel]="doc_obri_cb5"
                                    [p-options]="options_doc_obri_cb5"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb6"
                                    formControlName="doc_obri_cb6"  
                                    [ngModel]="doc_obri_cb6"
                                    [p-options]="options_doc_obri_cb6"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_obri_cb7"
                                    formControlName="doc_obri_cb7"  
                                    [ngModel]="doc_obri_cb7"
                                    [p-options]="options_doc_obri_cb7"
                                >
                                </po-checkbox-group>
                            </div>
                        
                        
                        
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        
                                <po-divider></po-divider>
                        
                                <div class="row">
                                    <h3>Documentos Opcionais</h3>
                                </div>
                        
                                <po-checkbox-group
                                    name="doc_op_cb1"
                                    formControlName="doc_op_cb1"  
                                    [ngModel]="doc_op_cb1"
                                    [p-options]="options_doc_op_cb1"
                                >
                                </po-checkbox-group>
                        
                                <po-checkbox-group
                                    name="doc_op_cb2"
                                    formControlName="doc_op_cb2"  
                                    [ngModel]="doc_op_cb2"
                                    [p-options]="options_doc_op_cb2"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb3"
                                    formControlName="doc_op_cb3"  
                                    [ngModel]="doc_op_cb3"
                                    [p-options]="options_doc_op_cb3"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb4"
                                    formControlName="doc_op_cb4"  
                                    [ngModel]="doc_op_cb4"
                                    [p-options]="options_doc_op_cb4"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb5"
                                    formControlName="doc_op_cb5"  
                                    [ngModel]="doc_op_cb5"
                                    [p-options]="options_doc_op_cb5"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb6"
                                    formControlName="doc_op_cb6"  
                                    [ngModel]="doc_op_cb6"
                                    [p-options]="options_doc_op_cb6"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb7"
                                    formControlName="doc_op_cb7"  
                                    [ngModel]="doc_op_cb7"
                                    [p-options]="options_doc_op_cb7"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb8"
                                    formControlName="doc_op_cb8"  
                                    [ngModel]="doc_op_cb8"
                                    [p-options]="options_doc_op_cb8"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb9"
                                    formControlName="doc_op_cb9"  
                                    [ngModel]="doc_op_cb9"
                                    [p-options]="options_doc_op_cb9"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb10"
                                    formControlName="doc_op_cb10"  
                                    [ngModel]="doc_op_cb10"
                                    [p-options]="options_doc_op_cb10"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb11"
                                    formControlName="doc_op_cb11"  
                                    [ngModel]="doc_op_cb11"
                                    [p-options]="options_doc_op_cb11"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb12"
                                    formControlName="doc_op_cb12"  
                                    [ngModel]="doc_op_cb12"
                                    [p-options]="options_doc_op_cb12"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb13"
                                    formControlName="doc_op_cb13"  
                                    [ngModel]="doc_op_cb13"
                                    [p-options]="options_doc_op_cb13"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb14"
                                    formControlName="doc_op_cb14"  
                                    [ngModel]="doc_op_cb14"
                                    [p-options]="options_doc_op_cb14"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb15"
                                    formControlName="doc_op_cb15"  
                                    [ngModel]="doc_op_cb15"
                                    [p-options]="options_doc_op_cb15"
                                >
                                </po-checkbox-group>
                                <po-checkbox-group
                                    name="doc_op_cb16"
                                    formControlName="doc_op_cb16"  
                                    [ngModel]="doc_op_cb16"
                                    [p-options]="options_doc_op_cb16"
                                >
                                </po-checkbox-group> 
                            </div> 
                        </ng-container>
                    </div>
                </div>
                
                <div>
                    <po-url 
                        name="linkAnexos" 
                        [(ngModel)]="inputsJson.linkAnexos"
                        formControlName="linkAnexos"
                        p-label="Insira aqui o(s) link(s) do(s) anexo(s)"
                        [p-disabled]="isViewInput"
                        ></po-url>

                        
                    <po-textarea 
                        name="obsDocumentos" 
                        [(ngModel)]="inputsJson.obsDocumentos"
                        formControlName="obsDocumentos"
                        p-label="Observação"
                        [p-disabled]="isViewInput"
                    ></po-textarea>
                </div>
                <div style="display: flex; justify-content: space-between;margin-bottom: 80px;">
                    <po-button p-icon="po-icon-arrow-left" class="round-button"  (p-click)="hideOrShow('documentosVolta')"></po-button>    
                    <po-button  p-label="Enviar" class="round-button" (p-click)="onSubmit()" *ngIf="!isViewInput"></po-button> 
                </div>
            </div>
        </po-widget> 
</form>
