interface inputsOpenProcess {
    cliente_corrente: string;
    cod_sap?: string;
    cnpj: string;
    nome_empresa: string;
    pais: string;
    cep: number;
    estado: string;
    cidade: string;
    segmento: string;
    nome_solic: string;
    email_solic: string;
    ddi_solic?: string;
    tel_solic?: string;
    cel_solic?: string;
    nome_tec?: string;
    ddi_tec?: string;
    tel_tec?: string;
    cel_tec?: string;
    email_tec?: string;

    nome_projeto: string;
    tipo_venda: string;
    idioma_proposta: string;
    moeda: string;
    neg_prev_forn: string;
    tipo_negocio: string;

    pais_fatura: string;
    uf_fatura_br?: string;
    uf_fatura?: string;
    autoriza_divulg: string;
    period_contrato: string;
    outro_periodo?: string;
    prazo_pgto: string;
    outro_prazo_pgto?: string;
    condi_entrega?: string;
    obs_cenario?: string;
    obs_neg_prev_fornc?: string;
    
    prazo_pgto_fornec: string;
    moeda_invo_bom: string;
    valor_invo_bom: number;
    incoterm: string;
    pais_origem_fornec: string;
    qtde_envio?: string;
    peso_envio?: number;
    cubagem_envio?: number;
    tratativa_import: string;
    qual?: string;
    obs_consolid_fornec_indicado?: string;

    pais_destino: string;
    estado_destino: string;
    incoterm_exportacao: string;
    certif_origem: string;
    seguro_exportacao: string;
    obs_exportacao?: string;
    linkAnexos?: string;
    obsDocumentos?: string;
  }

interface MontagemPropostaContent { 
  numProposta: string; 
  rfq_revisao: string; 
  documentid: number; 
  nome_projeto: string; 
  nome_empresa: string; 
  cod_sap: string; 
}

interface IMyData {
  cnpj: any;
  numProposta: string;
  documentid: number;
  nome_empresa: string;
  nome_projeto: string;
  rfq_revisao: number;
}

interface ComboOptions {
  clientes: ComboOptions[];
  paises: ComboOptions[];
  ddi: ComboOptions[];
  clientesModal: ComboOptions[];
  estados: ComboOptions[];
}

interface ComboOption {
  value: string;
  label: string;
}

interface ClienteContent {
  CODIGOCLIENTE: string;
  CODCLIENTE: string;
  PESQUISA: string;
  NOMEEMPRESA: string;
}

interface PaisContent {
  descricao: string;
  pesquisa: string;
  codPais: string;
}

interface DdiPaisContent {
  DDI: string;
  PESQUISA: string;
}

interface EstadosContent {
  descricaoRegioes: string;
  pesquisa: string;
  codRegiao: string;
}

interface IGetClient{ 
  cnpj: string; 
  isClient:boolean; 
  isRepresentante:boolean; 
  email:string
}

class ComboOptions {
  rfq_revisao!: string;
  
  
  constructor() {
    this.clientes;
    this.paises;
    this.ddi;
    this.clientesModal;
    this.estados;
  }
}



  class inputsOpenProcess {
    constructor() {
        this.cliente_corrente;
        this.cod_sap;
        this.cnpj;
        this.nome_empresa;
        this.pais;
        this.cep;
        this.estado;
        this.cidade;
        this.segmento;
        this.nome_solic;
        this.email_solic;
        this.ddi_solic;
        this.tel_solic;
        this.cel_solic;
        this.nome_tec;
        this.ddi_tec;
        this.tel_tec;
        this.cel_tec;
        this.email_tec;
        this.nome_projeto;
        this.tipo_venda;
        this.idioma_proposta;
        this.moeda;
        this.neg_prev_forn;
        this.tipo_negocio;
        this.pais_fatura;
        this.uf_fatura_br;
        this.uf_fatura;
        this.autoriza_divulg;
        this.period_contrato;
        this.outro_periodo;
        this.prazo_pgto;
        this.outro_prazo_pgto;
        this.condi_entrega;
        this.obs_cenario;
        this.obs_neg_prev_fornc;
        this.prazo_pgto_fornec;
        this.moeda_invo_bom;
        this.valor_invo_bom;
        this.incoterm;
        this.pais_origem_fornec;
        this.qtde_envio;
        this.peso_envio;
        this.cubagem_envio;
        this.tratativa_import;
        this.qual;
        this.obs_consolid_fornec_indicado;
        this.pais_destino;
        this.estado_destino;
        this.incoterm_exportacao;
        this.certif_origem;
        this.seguro_exportacao;
        this.obs_exportacao;
        this.linkAnexos;
        this.obsDocumentos;
    }
  }

  export {
    inputsOpenProcess,
    MontagemPropostaContent,
    ComboOptions,
    EstadosContent,
    DdiPaisContent,
    PaisContent,
    ClienteContent,
    ComboOption,
    IMyData,
    IGetClient
  };