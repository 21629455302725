// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url:'https://fluig-dev.hi-mix.com.br:8090',
  consumerKey:'oauthPortalHiMix',
  consumerSecret:'b2F1dGhQb3J0YWxIaU1peA==',
  tokenKey:'b5bad10a-066a-403d-af57-d81839866090',
  tokenSecret:'23acc171-3546-42cc-bdcf-36d61f7e9c8d387f3ae9-cdb3-4fea-9abe-6ce98dbe600d',
  urlPortal:'https://portalcliente-dev.hi-mix.com.br'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
