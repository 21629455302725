import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class EditInvoicePackingService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) {}

   apiUrl: any;

   async getDataGrid(documentid:number){
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
      
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_FRM_EMISSAO_INVOICE_PACKINGLIST",
          constraints:[{
            _field: "documentid", 
            _initialValue: documentid, 
            _finalValue: documentid, 
            _type: 1
          }] 
        }
        
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            async (response: any) => {
              resolvePromise({
                response: await this.addColumnStatus(response.content.values),
                totalHits: response.content.values.length
              });
            }
          );
        });
   }

   private async addColumnStatus(valores:any[]){
    for (const item of valores) {
      const { pesagem }: any = await this.getPesagem(item['recebeRemessa']);
      item['status'] = "onApproval";
      item['pesoBruto'] = pesagem;
      item['recebeRemessa'] = item['recebeRemessa'] == null ? null : item['recebeRemessa'].split(",")[1];
    }
    return  valores
  }

  async getPesagem(numRemessa:number){
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
      
    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_GET_PESAGEM",
      constraints:[{
        _field: "REMESSA", 
        _initialValue: numRemessa, 
        _finalValue: numRemessa, 
        _type: 1
      }] 
    }
    
    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            pesagem: response.content.values[0].pesoBruto,
            totalHits: response.content.values.length
          });
        }
      );
    });
  }

   async getFiles(id:number) {
      let  {response} : any = await this.getListFolder('Emissão - Invoice e Packing List',undefined) as { DESCRIPTION: string, ID: number };
      const value : any = await this.getListFolder(undefined,response[0].ID)
      const result = value.response.filter((val:any) => val.ID == id)
      const files =  await this.getListFolder(undefined,result[0].ID)
      const data = await this.getUrl(files)
      return data
    }

    async getListFolder(nameFolder?:string,idFolder?:number) {
        this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
      
    
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_LISTFOLDER",
          constraints:[] as any
        }
        
        if(nameFolder){
          body.constraints.push({
            _field: "NAME_FOLDER", 
            _initialValue: nameFolder, 
            _finalValue: nameFolder, 
            _type: 1
          })
        }

        if(idFolder){
          body.constraints.push({
            _field: "ID_FOLDER", 
            _initialValue: idFolder, 
            _finalValue: idFolder, 
            _type: 1
          })
        }
    
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values,
                totalHits: response.content.values.length
              });
            }
          );
        });
    }

    async getUrl(listFiles?:any) {
      const {response} = listFiles
  
        this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
      
    
        const requestData = {
          url: this.apiUrl,
          method: 'POST'
        };
    
        const body = {
          name: "DTS_GET_URL",
          constraints:[{
            _field: "LIST_FILES", 
            _initialValue: JSON.stringify(response), 
            _finalValue: JSON.stringify(response), 
            _type: 1
          }]
        }
        
    
        const httpOptions = this.authenticator.connect(requestData)
        
        return new Promise((resolvePromise) => {
          this.http.post(requestData.url, body, httpOptions).subscribe(
            (response: any) => {
              resolvePromise({
                response: response.content.values,
                totalHits: response.content.values.length
              });
            }
          );
        });
    }


   moveRequest(documentId:number,aprovOrReprov:string,justificativa?:any, observacao?:any) {
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    
    const body = {
      name: "DTS_SAVE_DATA_IN_THE_FIELD",
      constraints: [ {
        _field: 'documentId', 
        _initialValue: documentId, 
        _finalValue: documentId, 
        _type: 1
      },{
        _field: 'decisao_portal', 
        _initialValue: aprovOrReprov, 
        _finalValue: aprovOrReprov, 
        _type: 1
      }
    ]
    };


    if(justificativa != ''){
      body.constraints.push({
        _field: "observacao_portal", 
        _initialValue: justificativa, 
        _finalValue: justificativa, 
        _type: 1
      })
    }
    
    if(observacao != ''){
      body.constraints.push({
        _field: "observacao_portal", 
        _initialValue: observacao, 
        _finalValue: observacao, 
        _type: 1
      })
    }

    const httpOptions = this.authenticator.connect(requestData)
    
    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values , 
            totalHits: response.content.values.length 
          });
        }
      );
    });
  }
}
