<po-table
  [p-auto-collapse]="true"
  [p-columns]="columns"
  [p-hide-columns-manager]="true"
  [p-hide-table-search]="false"
  [p-items]="items"
  [p-sort]="true"
  [p-striped]="true"
>

  <ng-template
    p-table-row-template
    let-rowItem
    let-i="rowIndex"
  >
    <po-widget >
      <po-table
        [p-columns]="columns2"
        [p-items]="rowItem.ATTACHMENT"
        [p-hide-columns-manager]="true"
      >
      </po-table>
    </po-widget>
  </ng-template>
</po-table>