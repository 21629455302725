import { Component, OnInit } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import { ListInvoicePackingService } from './list-invoice-packing.service';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/i18n/translation.service';


@Component({
  selector: 'app-list-invoice-packing',
  templateUrl: './list-invoice-packing.component.html',
  styleUrls: ['./list-invoice-packing.component.css']
})
export class ListInvoicePackingComponent implements OnInit {
  
  constructor(
    private service: ListInvoicePackingService,
    private route: Router,
    private translationService: TranslationService
  ) { }

  literals: any = [];
  gridItems: any[] = [];
  gridColumns:Array<PoTableColumn> = []

  ngOnInit() {
    this.service.getDatas().then(({ response}: any)=> {
      this.gridItems = response
    })

    this.loadLiterals();
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }

  extras(row: any) {
    //if(row.NUM_SEQ_ESTADO == 'Em Andamento'){
      this.route.navigate(["/aprovainvoice",`${row?.DOCUMENTID}`], { queryParams: { d: row?.DATAABERTURA }})
    //}
  }

  loadValues(){
    this.gridColumns = [ 
      { label: this.literals.processNumber, property: 'NUMPROCESS'},  
      { label: this.literals.openingDate, property: 'DATAABERTURA',  type: 'date'},
      {
        property: 'status',
        type: 'label',
        labels: [
          { 
            value: "onApproval",
            color: 'color-08',
            label: this.literals.inApproval
          },{ 
            value: 'approved', 
            color: 'color-11', 
            label: this.literals.approved,
            textColor:'white'
          },{ 
            value: 'disapproved', 
            color: 'color-07', 
            label: this.literals.rejected,
            textColor:'white' 
          }
        ]
      },
      { label: this.literals.shipmentNumber, property: 'REMESSA', visible: false },
      { 
        label: this.literals.documentValidation, 
        property: 'status',
        type:'icon',
        icons: [
          { 
            value: 'onApproval', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.approveRequest,  
            action: this.extras.bind(this),
          },{ 
            value: 'disabled', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest,  
            action: this.extras.bind(this),
          }
      ]
      },
    ]
  }
}
