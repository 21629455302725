import { Component, ViewChild, OnInit  } from '@angular/core';
import { AuthService } from '../../../services/auth.service'
import { PoModalComponent, PoNotificationService, PoToasterOrientation  } from '@po-ui/ng-components';
import { PoModalPasswordRecoveryComponent, PoModalPasswordRecoveryType, PoPageLoginLiterals, PoPageLoginRecovery } from '@po-ui/ng-templates';

import { Router } from '@angular/router'
import {LoginService} from './login.service'
import {UtilsService} from '../../../services/utils.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(PoModalComponent, { static: true }) poModal!: PoModalComponent;
  @ViewChild(PoModalPasswordRecoveryComponent) passwordRecoveryModal!: PoModalPasswordRecoveryComponent;
  email!:any;

  constructor(
    private auth: AuthService,
    private service: LoginService,
    private router: Router,
    public notification: PoNotificationService,
    public utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    if(this.auth.isLoggedIn() && this.auth.isLoggedSecond()){
      this.router.navigate(['/'])
    }
  }
  
  usuario!:string;
  senhaUsuario!:string;

  literals:PoPageLoginLiterals = {
    attempts: '{0} vez(es) ',
    createANewPasswordNow: 'Melhor criar uma senha nova agora! Você vai poder entrar no sistema logo em seguida.',
    forgotPassword: 'Esqueceu sua senha?',
    forgotYourPassword: 'Esqueceu sua senha?',
    highlightInfo: '',
    iForgotMyPassword: 'Esqueci minha senha',
    ifYouTryHarder: 'Se tentar mais ',
    welcome: 'Boas-vindas',
    loginErrorPattern: 'Login obrigatório',
    loginHint: '',
    loginLabel: 'Insira seu e-mail',
    loginPlaceholder: 'Insira seu e-mail para acesso',
    passwordErrorPattern: 'Senha obrigatória',
    passwordLabel: 'Insira sua senha',
    passwordPlaceholder: 'Insira sua senha de acesso',
    customFieldErrorPattern: 'Campo customizado inválido',
    customFieldPlaceholder: 'Por favor insira um valor',
    registerUrl: 'Novo registro',
    rememberUser: 'Lembrar usuário',
    rememberUserHint: 'Esta opção pode ser desabilitada nas configurações do sistema',
    submitLabel: 'Acessar',
    submittedLabel: 'Carregando...',
    titlePopover: 'Opa!',
    yourUserWillBeBlocked: 'sem sucesso seu usuário será bloqueado e você fica 24 horas sem poder acessar :('
  };

  async login(){
    if((!this.usuario && !this.senhaUsuario) || (!this.usuario || !this.senhaUsuario)){
      this.notification.error({ 
        message: `Campo E-mail e Senham devem ser preenchidos!`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      return 
    }
    await this.auth.login(this.usuario.toUpperCase() ,this.senhaUsuario)
  }

  openModal(){
    this.passwordRecoveryModal.open()
  }

  async isExistingUser(value: string){
    const {email}:any = value;
    const {response,id} = await this.utilsService.isExistingEmail(email);

    if(!response){
      this.notification.warning({ 
        message: `E-mail ${email} não encontrado`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
    }else{
      this.service.envioEmail({email, id:id})
      this.passwordRecoveryModal.completed()
    }
  }

}
