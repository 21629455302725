import { Injectable } from '@angular/core';
import { PoI18nService, PoLanguageService } from '@po-ui/ng-components';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  currentLanguage: string;

  constructor(
    private poI18nService: PoI18nService, 
    private languageService: PoLanguageService
  ) {
    this.currentLanguage = this.languageService.getShortLanguage();
  }

  loadLiterals(context: string, language?: string): Observable<any> {
    language = language || this.currentLanguage;
    return this.poI18nService.getLiterals({ context, language });
  }

  changeLanguage(language: string) {
    this.currentLanguage = language;
  }
}

