import { Component, OnInit,ViewContainerRef,Input  } from '@angular/core';
import {  inputsProposta } from './proposta.interface';
import { FormGroup,FormControl,Validators,FormArray } from '@angular/forms'
import { UtilsService } from '../../../services/utils.service';
import { PoComboFilterMode  } from '@po-ui/ng-components';
import { PropostaService } from './proposta.service';
import { SharedService } from '../../../shared/shared.service'
import Rules from '../../../services/rulesInputs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.css']
})

export class PropostaComponent implements OnInit {
  @Input('total-hits')
  totalHits = 0;
  inProposta:any = new inputsProposta();
  propostaForm!: FormGroup
  isLoadingInput:boolean = true;
  isProgramEntrega1:boolean = false;
  isProgramEntrega2:boolean = false;
  isProgramEntrega3:boolean = false;
  isProgramEntrega4:boolean = false;
  isObsIsencaoImpost:boolean = false;
  isSumaziProdQual:boolean = false;
  isRepresentante:boolean = false;
  isCenario2:boolean = false;
  isCenario3:boolean = false;
  isCenario4:boolean = false;
  isProdCorrent:boolean = true;
  isCodProd:boolean = true;
  isNacional:boolean = true;
  isindicProd:boolean = true;
  isViewInput:any = false;
  isPpb:boolean = true;
  rules: any = Rules;
  isCodProdCorrent:boolean = false;
  base64Output!: string;
  isIsencaoImpost:boolean = true;
  isReqCri:boolean = true;
  optionsMaterias: any[] = [];
  camposDocumentos: any[] = [];
  campos: any[] = [];
  materiais: any[] = [];
  itensProposta: any[] = [];
  itensCamposProposta!: any;
  modeFilter: PoComboFilterMode = PoComboFilterMode.contains;

  yesOrNot = [
    { value: 'sim', label: 'Sim' },
    { value: 'nao', label: 'Não' }
  ];

  moeda = [
    {value:"BRL",label:"BRL (R$)"},
    {value:"USD",label:"USD ($)"}
  ]

  periodoConsumo = [
    { value:"anual",label:"Anual"},
    { value:"mensal",label:"Mensal"},
    { value:"trimestral",label:"Trimestral"},
    { value:"spot",label:"Spot"}
  ]

  tipoProducao1 = [
    { value:"produc_lote_unic",label:"Lote único"},
    { value:"produc_npi",label:"NPI"},
    { value:"produc_serie",label:"Produção em Série"},
    { value:"prototipo",label:"Protótipo"}
  ]
  tipoProducao2 = [
    { value:"produc_lote_unic",label:"Lote único"},
    { value:"produc_npi",label:"NPI"},
    { value:"produc_serie",label:"Produção em Série"},
    { value:"prototipo",label:"Protótipo"}
  ]
  tipoProducao3 = [
    { value:"produc_lote_unic",label:"Lote único"},
    { value:"produc_npi",label:"NPI"},
    { value:"produc_serie",label:"Produção em Série"},
    { value:"prototipo",label:"Protótipo"}
  ]
  tipoProducao4 = [
    { value:"produc_lote_unic",label:"Lote único"},
    { value:"produc_npi",label:"NPI"},
    { value:"produc_serie",label:"Produção em Série"},
    { value:"prototipo",label:"Protótipo"}
  ]

  programEntrega = [
    { value:"forecast",label:"Forecast"},
    { value:"pedido_firme",label:"Pedido Firme"},
  ]

  finalidadeProd = [
    { value:"consu_final",label:"Consumo Final"},
    { value:"industria",label:"Indústria (Matéria Prima ou Consumo)"},
    { value:"revenda",label:"Revenda"},
  ]

  isencaoImpost = [
    { value:"nao_possui",label:"Não Possui Isenção"},
    { value:"possui_ipi",label:"Isenção IPI"},
    { value:"posuui_pis_cofins",label:"Isenção PIS/COFINS"},
    { value:"possui_ipi_piscofins",label:"Isenção do IPI e PIS/COFINS"},
  ]

  especSolda = [
    { value:"lead_free",label:"Lead-free"},
    { value:"tin_lead",label:"Tin-lead"},
    { value:"rohs",label:"RoHS"}
  ]

  ipcaOptions = [
    { value:"class1",label:"Classe 1 - Produtos Eletrônicos de Consumo Geral"},
    { value:"class2",label:"Classe 2 - Produtos Eletrônicos de Serviço Dedicado"},
    { value:"class3",label:"Classe 3 - Produtos Eletrônicos de Alto Desempenho"}
  ]

  cobraNre = [
    { value:"reemb_desp",label:"Reembolso de despesas (serviços)"},
    { value:"venda_merc",label:"Venda de Mercadorias"},
    { value:"uso_cons",label:"Uso/Consumo"},
    { value:"amortizar",label:"Amortizar no Preço do Produto"}
  ]


  constructor(
    private sharedService : SharedService,
    private propostaService: PropostaService,
    private viewContainerRef: ViewContainerRef,
    private utilsService: UtilsService,
    private auth: AuthService,
    ) {}

    
    

  ngOnInit() :void {
    this.isRepresentante = this.auth.isRepresentante()
    
    this.sharedService.isProdCorrent$.subscribe((value) : any => {
      this.isProdCorrent = value
    })

    this.sharedService.tipoVenda$.subscribe((value) : any => {
      this.isPpb = value == 'nacional' ? true : false
    })

    this.isViewInput = this.sharedService.getIsView

    


    this.sharedService.dadosPropostas$.subscribe((value: any)=> {
      this.itensProposta.push(value[0])
      this.itensCamposProposta = value[1]
    })
    

    this.sharedService.isCodProd$.subscribe((value) : any => {
      this.isCodProd = value
    })

    this.sharedService.isindicProd$.subscribe((value) : any => {
      this.isindicProd = value
    })

    this.sharedService.isNacional$.subscribe((value): any => {
      this.isIsencaoImpost = value
    })
 
    this.sharedService.isReqCri$.subscribe((value) : any => {
      this.isReqCri = value
    })
         
    

    this.propostaForm = new FormGroup({
      item_prod_proposta: new FormControl(''),
      produto_corrente: new FormControl(''),
      cod_produto: new FormControl(''),
      ncm: new FormControl(''),
      mod_pn_prod: new FormControl(''),
      apli_prod: new FormControl(''),
      func_prod: new FormControl(''),
      moeda_target_price: new FormControl(''),
      target_price: new FormControl(''),
      qtde_consumo1: new FormControl(''),
      periodo_consumo1: new FormControl(''),
      tipo_producao1: new FormControl(''),
      program_entrega1: new FormControl(''),
      prazo_entreg_consumo1: new FormControl(''),
      qtde_consumo2: new FormControl(''),
      periodo_consumo2: new FormControl(''),
      tipo_producao2: new FormControl(''),
      program_entrega2: new FormControl(''),
      prazo_entreg_consumo2: new FormControl(''),
      qtde_consumo3: new FormControl(''),
      periodo_consumo3: new FormControl(''),
      tipo_producao3: new FormControl(''),
      program_entrega3: new FormControl(''),
      prazo_entreg_consumo3: new FormControl(''),
      qtde_consumo4: new FormControl(''),
      periodo_consumo4: new FormControl(''),
      tipo_producao4: new FormControl(''),
      program_entrega4: new FormControl(''),
      prazo_entreg_consumo4: new FormControl(''),
      simi_prod: new FormControl(''),
      indic_prod: new FormControl(''),
      aceit_altern: new FormControl(''),
      finalidade_prod: new FormControl(''),
      possui_ppb: new FormControl(''),
      isencao_impost: new FormControl(''),
      req_cri_espec: new FormControl(''),
      sumazi_prod: new FormControl(''),
      sumazi_prod_qual: new FormControl(''),
      obs_isencao_impost: new FormControl(''),
      espec_solda: new FormControl(''),
      ipca: new FormControl(''),
      certif_exter: new FormControl(''),
      verniz_resi: new FormControl(''),
      equi_fw_teste: new FormControl(''),
      cobra_nre: new FormControl(''),
      jigas_cliente: new FormControl(''),
      obs_obj_proposta: new FormControl(''),
      proposta: new FormArray([
       
      ])
    })

    this.sharedService.updateDadosFormProposta(this.propostaForm )
    
    this.propostaForm.valueChanges.subscribe(values => {
      this.sharedService.atualizarDadosCampo('');
    })
    
    
    this.propostaService.getMateriasOptions(this.sharedService.getCodCliente).then(({ response }: any)=> {

      for (let data of response) {
        this.materiais.push(data)
      }

      this.optionsMaterias = response.map((val: { CODIGOMATERIAL: string; PESQUISA: string; }) => {
        return { value:val.CODIGOMATERIAL,label:val.PESQUISA }
      })

      
      this.itensProposta[0].forEach((val: any) => {
        this.itensCamposProposta.forEach((coluna: any) => {
          let value = val[coluna]
          if (value) {

            
            if(coluna.includes("program_entrega")){
              (this as any)[`isProgramEntrega${coluna[coluna.length - 1]}`] = true
            }else if(coluna.includes("periodo_consumo")){
              (this as any)[`isCenario${coluna[coluna.length - 1]}`] = true
            }else if(coluna == "produto_corrente"){
              this.isProdCorrent = true
            }else if(coluna == "cod_produto"){
              this.isCodProd = true
            }else if(coluna.includes("prazo_entreg_consumo")){
              (this as any)[`isCenario${coluna[coluna.length - 1]}`] = true
              setTimeout(() => {
                value = this.utilsService.formatDate(value)
                this.propostaForm.get(coluna)?.setValue(value)
              }, 0);
            }

            
            this.propostaForm.get(coluna)?.setValue(value)
          }
        });
      });
      

      this.isLoadingInput = false
    })


   
  }
    

  enableOrDisabled(campo: any){   
    const { name,selectedValue }= campo

    if(name == 'produto_corrente' && selectedValue == 'sim'){
      this.isCodProdCorrent = true
    }else if(name == 'produto_corrente' && selectedValue == 'nao'){
      this.isCodProdCorrent = false
    }

    const regras = this.rules[`${name}`][`${selectedValue}`];

    for (let regra of regras) {
      let inputKeys: any[] = Object.keys(regra);
      (this as any).sharedService[`${inputKeys[0]}`].next(regra[inputKeys[0]]);
    }

  }

  enableOrDisabledlocal(campo: any){   
    
    const { name,selectedValue }= campo
    let nameVar = ''

   if(name == 'periodo_consumo1' || name == 'periodo_consumo2' || name == 'periodo_consumo3' || name == 'periodo_consumo4'){
    
      this.propostaForm.get(`tipo_producao${name[name.length - 1]}`)?.setValue('');
      
      nameVar = `isProgramEntrega${[`${name[name.length - 1]}`]}` ;
      (this as any)[`tipoProducao${name[name.length - 1]}`] = [];
      (this as any)[`tipoProducao${name[name.length - 1]}`] = [
        { value:"produc_lote_unic",label:"Lote único"},
        { value:"produc_npi",label:"NPI"},
        { value:"produc_serie",label:"Produção em Série"},
        { value:"prototipo",label:"Protótipo"}
      ];
      if(selectedValue == 'spot'){
        (this as any)[`${[nameVar]}`] = false;
        (this as any)[`tipoProducao${name[name.length - 1]}`] = (this as any)[`tipoProducao${name[name.length - 1]}`].filter((item: { value: string; }):any => item.value !== "produc_serie");

      }else{
        (this as any)[`${[nameVar]}`] = true;
        (this as any)[`tipoProducao${name[name.length - 1]}`] = (this as any)[`tipoProducao${name[name.length - 1]}`].filter((item: { value: string; }):any => item.value == "produc_serie");
      }
    }
    
    if(name == 'qtde_consumo2' || name == 'qtde_consumo3' || name == 'qtde_consumo4'){
        nameVar = `isCenario${name[name.length - 1]}`;  

        (this as any)[`${[nameVar]}`] = !(this as any)[`${[nameVar]}`]
        
    } 
    
    if(name == 'isencao_impost'){

        if(selectedValue == 'nao_possui'){
          this.isObsIsencaoImpost = false
        }else{
          this.isObsIsencaoImpost = true
        }
    } 
    
    if(name == 'sumazi_prod'){

        if(selectedValue == 'sim'){
          this.isSumaziProdQual = true
        }else{
          this.isSumaziProdQual = false
        }
    } 


    
    
  }

  preencherInfosProduto(value: string){
    const {CODIGOMATERIAL,NCM,DESCRICAO} = this.materiais.find(val => val.CODIGOMATERIAL == value)
    
    this.inProposta.cod_produto = CODIGOMATERIAL
    this.inProposta.ncm = NCM;
    this.inProposta.mod_pn_prod = DESCRICAO
    this.sharedService.insertDocuments(DESCRICAO);

    this.addProductDocuments(DESCRICAO)

  } 

  formatarValor(valor: string,decimais: number,id:string) {
    let valorNumerico = parseFloat(valor.replace(/\D/g, '')) / 100; 
    this.inProposta[id] = valorNumerico.toLocaleString('pt-BR', { minimumFractionDigits: decimais }); 
  }

  formatarValorNumbers(valor: string, id:string) {
    if(valor != ''){
      let valorNumerico = parseInt(valor.replace(/\D/g, ''), 10);
      this.inProposta[id] = valorNumerico.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); 
    }
  }

  addProductDocuments(value:any){
    this.sharedService.curentProduct(value)
  }

  onFileSelected(event :any) {
    
    this.utilsService.convertFile(event.file.rawFile).subscribe(base64 => {
      this.base64Output = base64;

      this.sharedService.postDadosFiles([{
        base64,
        name:event.file.name
      }])
    });
  }

  deleteProposta(i:number){
    this.proposta.removeAt(i)
  }

  get proposta(){
    return this.propostaForm.get('proposta') as any
  }

  addProposta(){
    let count = 0 
    let teste:any = {}
    teste['ncm'[`${count}`]] =  new FormControl(''),
    this.proposta.push(
      new FormGroup(teste)
    )
  }
  

}


