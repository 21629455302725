import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthenticatorService } from '../../../services/authenticator.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }


  async envioEmail({email, id}: {email:string,id:String}){
    const requestData = {
      url: `${environment.url}/api/public/ecm/dataset/datasets`,
      method: 'POST'
    };

    const conteudo:String = `Olá,<br><br>  
  Recebemos uma solicitação para redefinir a senha da sua conta. Se você fez essa solicitação, por favor, clique no link abaixo para alterar sua senha:<br><br>  
  <a href="${environment.urlPortal}/changepassword/${id}">Redefinir Senha</a><br><br>  
  Se você não solicitou a redefinição de senha, ignore este e-mail. Sua senha permanecerá a mesma.<br><br>  
  Atenciosamente,<br>
  Equipe de Suporte`
  
    const body = {
      name: "DTS_ENVIOEMAIL",
      constraints: [
        {
          _field: "nomeRemetente", 
          _initialValue: 'Hi-Mix Eletrônicos S/A', 
          _finalValue: 'Hi-Mix Eletrônicos S/A', 
          _type: 1
        },{
          _field: "assunto", 
          _initialValue: 'RESET DE SENHA PORTAL HI-MIX', 
          _finalValue: 'RESET DE SENHA PORTAL HI-MIX', 
          _type: 1
        },{
          _field: "conteudo", 
          _initialValue: conteudo, 
          _finalValue: conteudo, 
          _type: 1
        },{
          _field: "destinatario", 
          _initialValue: JSON.stringify([email]), 
          _finalValue: JSON.stringify([email]), 
          _type: 1
        }
      ]
    };

  
    const httpOptions = this.authenticator.connect(requestData);


    const response: any = await this.http.post(requestData.url, body, httpOptions).toPromise()

  }
}
