
<!-- 
  <div class="center" style="margin-top: 150px;">
   
      <div >
        <po-button
        
        *ngIf="hasConsulta"
        p-type="primary"
        
        (p-click)="reedirect('/consultar')"
        p-label="Consultar Solicitação"
        
      >
      </po-button>
    
      <po-button
        p-type="primary"
        style="display: grid;width: 100%;"
        (p-click)="reedirect('/novasolicitacao')"
        p-label="Nova Solicitação"
      >
      </po-button>
    </div> -->
  

    
    
  <!-- </div> -->

<div class="main-home">
  <div class="container" >
      <span>{{ literals.welcome }}, {{name}}!</span>
  </div>
</div>

  

