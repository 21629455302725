<po-widget>

    <div class="po-row container-bread">
        <div class="po-col-12 po-md-6">
            <po-breadcrumb [p-items]="[
              { label: 'Invoice e Packing List', link: '/consultainvoice' },
              { label: 'Validação Documentos', link: '' }
            ]">
            </po-breadcrumb>
        </div>
    </div>

    <po-widget [p-primary]="true">
        <div style="display: flex;align-items: center;">
            <span class="po-icon po-icon-info" style="margin-right:15px;color:blue"></span>
            <ng-container>
                <span *ngIf="this.gridItems[0]?.observacao_corrigir_invoice != null ? false : true">Observação:
                    <strong>{{this.gridItems[0]?.obs_start}}</strong></span>
                <span *ngIf="this.gridItems[0]?.observacao_corrigir_invoice != null ? true : false">Observação:
                    <strong>{{this.gridItems[0]?.observacao_corrigir_invoice}}</strong></span>
            </ng-container>
        </div>
    </po-widget>

    <po-table style="width: 100%;" [p-columns]="gridColumns" [p-items]="gridItems" [p-sort]="true"
        [p-hide-columns-manager]="true" [p-loading]="isLoading">
        <ng-template p-table-row-template let-rowItem let-i="rowIndex">
            <po-widget p-title={{this.literals.itemsAttachedProcess}}>
                <po-table style="width: 100%;" [p-columns]="gridColumnsFiles" [p-items]="gridItemsFiles" [p-sort]="true"
                    [p-hide-columns-manager]="true" [p-loading]="isLoadingFiles">
                </po-table>
            </po-widget>
        </ng-template>
    </po-table>
    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngIf="isReproved">
        <po-textarea class="po-md-12" [p-required]="true" [p-show-required]="false" [(ngModel)]="campoJustificativa"
            p-label={{this.literals.justifyRejection}}*>
        </po-textarea>
    </div>
    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngIf="isApproved">
        <po-textarea class="po-md-12" [(ngModel)]="observacao" p-label={{this.literals.observation}}>
        </po-textarea>
    </div>

    <div class="po-row">
        <po-button class="po-md-6" p-icon="po-icon po-icon-close" [p-danger]="true" p-label={{this.literals.reject}}
            (p-click)="handleApprovedOrReproved('reproved')">
        </po-button>
        <po-button class="po-md-6" p-icon="po-icon po-icon-ok" p-label={{this.literals.approve}}
            (p-click)="handleApprovedOrReproved('approved')">
        </po-button>
    </div>

    <div style="display: flex;justify-content: end;margin-top: 15px;">
        <po-button class="po-md-4" p-icon="po-icon po-icon-ok" p-label={{this.literals.submit}}
            (p-click)="handleSave()">
        </po-button>
    </div>
</po-widget>