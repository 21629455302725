import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {HomeService} from './home.service';
import { AuthService } from '../../../services/auth.service';
import { TranslationService } from 'src/app/services/i18n/translation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  literals: any = {};
  cnpj!:string;
  name!:string;
  hasConsulta: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private service: HomeService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.cnpj = this.authService.getUserData('CNPJ');
    this.name = this.authService.getUserData('NOME');
    
    this.service.isHaveRequest(this.cnpj).then(({ response }: any)=> {
      this.hasConsulta = response; 
    })

    this.loadLiterals();
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
    });
  }

  reedirect(path: string) {
    this.router.navigate([path])
  }



}


