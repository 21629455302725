import { Component, OnInit } from '@angular/core';
import { ConsultarService } from './consultar.service';
import { AuthService } from '../../../services/auth.service';
import { GridItem,FilterValues } from './consultar.interface';
import {UtilsService} from '../../../services/utils.service'
import { Router } from '@angular/router';
import { PoTableColumn } from '@po-ui/ng-components';
import {SharedService} from '../../../shared/shared.service';
import { TranslationService } from 'src/app/services/i18n/translation.service';

@Component({
  selector: 'app-consultar',
  templateUrl: './consultar.component.html',
  styleUrls: ['./consultar.component.css']
})
export class ConsultarComponent implements OnInit {
  
  constructor(
    private service: ConsultarService,
    private authService: AuthService,
    private sharedService : SharedService,
    private route: Router,
    private translationService: TranslationService
  ) { }

  literals: any = {};
  gridTotalHits = 0;
  options:any = [];
  pageSelect = {};
  lastId = 0;
  sizePage:number = 30;
  gridCurrentPage = 1;
  gridItems: GridItem[] = [];
  jsonFilters: any[] = []
  filters: FilterValues = new FilterValues()
  filterOptions: any = {
    RFQ_REVISAO: [],
    validarDespesasArea: [],
    NOME_EMPRESA: [],
    NUM_SEQ_ESTADO: [],
    aprovGestorData: [],
    idAtividade: [],
    nomeSolicitante: [],
    NUMPROPOSTA: [],
    ID: []
  }
  refreshingGrid = true;
  isRepresentante:boolean = false;
  disableFilters = false;
  firstIndex = 0;
  lastIndex = 0;

  gridColumns:Array<PoTableColumn> = [];

   ngOnInit(): void {
    this.isRepresentante = this.authService.isRepresentante()

    if(this.isRepresentante){
      this.gridColumns.push({ label: 'Empresa', property: 'NOME_EMPRESA'})
    }
    
    this.options.push({ 
      value: '5', 
      label: '5',
      select:true
    },{ 
      value: '15', label: '15' 
    },{ 
      value: '30', label: '30' 
    });

    this.onChangeFilters()
    this.filter()
    this.fetchGridData();
    this.loadLiterals();
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }
  

  extras(row: any) {
    if(row.NUM_SEQ_ESTADO == 'Em Andamento'){
      this.route.navigate(["/viewrequest",`${row?.DOCUMENTID}`])
    }else{
      this.route.navigate(["/aprovacao",`${row?.NUMPROPOSTA}-${row?.RFQ_REVISAO}`  ])
    }
  }

  

  private filter() {
    
    this.filterOptions = {
      RFQ_REVISAO: [],
      validarDespesasArea: [],
      NUM_SEQ_ESTADO: [],
      NOME_EMPRESA: [],
      aprovGestorData: [],
      idAtividade: [],
      nomeSolicitante: [],
      NUMPROPOSTA: [],
      ID: []
    }
    

    this.service.getFilterOptions(this.jsonFilters).then(({ response,totalHits,idLastPage }: any)=> {
      this.disableFilters = true;
      this.gridTotalHits = totalHits;
      this.lastId = idLastPage;
      this.lastIndex = response[0]['ID'] != '' ? 0 : response[0]['ID']
      this.prepareFilters(response)
      this.disableFilters = false;
      this.fetchGridData();
      
    })
  }
  private fetchGridData(signal? : string | undefined) {
    this.refreshingGrid = true;
    this.service.gridData(this.lastIndex,signal,this.sizePage,this.jsonFilters).then(({ response }: any)=> {
      this.gridItems = response
      
      if(response.length > 0){
        this.firstIndex = response[0]['ID']
        this.lastIndex = response[response.length-1]['ID']
      }
      
      this.refreshingGrid = false
    })
  }

  private prepareFilters(response: any[]) {
    const keys = Object.keys(this.filterOptions);
  
   
    response.forEach((value: any) => {
      keys.forEach(key => {
        const isValue = this.filterOptions[key].find((val: { value: string; }) => val.value == value[key])

        if (value && value[key] && !isValue) {
          
          this.filterOptions[key].push({
            value: value[key],
            label: value[key],
          });
        }
      })
      
    });
  }

  onChangePage({ currentPage } : any) {
    const signal = currentPage < this.gridCurrentPage ? "<" : ">"
    this.gridCurrentPage = currentPage;
    this.fetchGridData(signal);
  }

  onResetFilters() {
    this.filters = new FilterValues();

    this.onChangeFilters()
    this.filter()
    this.fetchGridData();
  }

  // handlerSizePage(value: any){
  //   this.gridCurrentPage = 1;
  //   this.fetchGridData();
  // }
  
  onChangeFilters() {
    this.jsonFilters = []
    if(this.filters['NUMPROPOSTA']?.length  ){
      this.filters['NUMPROPOSTA'].map(val => {
        this.jsonFilters.push( {
          _field: "NUMPROPOSTA", 
          _initialValue: val, 
          _finalValue: val, 
          _type: 1
        })
      })
     
    }
    
    if(this.filters['RFQ_REVISAO']?.length  ){
      this.filters['RFQ_REVISAO'].map(val => {
        this.jsonFilters.push( {
          _field: "RFQ_REVISAO", 
          _initialValue: val, 
          _finalValue: val, 
          _type: 1
        })
      })
    }
    
    if(this.filters['NOME_EMPRESA']?.length  ){
      this.filters['NOME_EMPRESA'].map(val => {
        this.jsonFilters.push( {
          _field: "NOME_EMPRESA", 
          _initialValue: val, 
          _finalValue: val, 
          _type: 1
        })
      })
    }
    
    if(this.filters['NUM_SEQ_ESTADO']?.length  ){
      this.filters['NUM_SEQ_ESTADO'].map(val => {
        this.jsonFilters.push( {
          _field: "NUM_SEQ_ESTADO", 
          _initialValue: val, 
          _finalValue: val, 
          _type: 1
        })
      })
    }

    this.jsonFilters.push( {
      _field: "EMAIL", 
      _initialValue: this.authService.getUserData('EMAIL'),
      _finalValue: this.authService.getUserData('EMAIL'),
      _type: 1
    })

    if(!this.isRepresentante && this.authService.getUserData('CODFUNCAO') != "16"){
      this.jsonFilters.push( {
        _field: "CNPJ", 
        _initialValue: this.authService.getUserData('CNPJ').replace(/\.|\-|\//g, ""),
        _finalValue: this.authService.getUserData('CNPJ').replace(/\.|\-|\//g, ""),
        _type: 1
      })
  }

    
    this.filter()
  }

  loadValues(){
    this.gridColumns = [ 
      { label: this.literals.commercialProposalNumber, property: 'NUMPROPOSTA'},
      { label: this.literals.revisionNumber, property: 'RFQ_REVISAO'},
      { label: this.literals.projectName, property: 'NOME_PROJETO'},
      { label: this.literals.openingDate, property: 'DATA_ABERTURA' },
      { label: this.literals.quotationStartDate, property: 'DATA_INIT' },
      { label: this.literals.deliveryDeadline, property: 'DATA_END' },
      { label: this.literals.inApprovalSince, property: 'DAT_MOVTO' },
      { 
        label: this.literals.status,
        property: 'NUM_SEQ_ESTADO' ,
        width: "30%",
        type:"label",
        labels: [
          { value: 'Em análise', color: 'rgba(217,217,217,1)', label: this.literals.inReview },
          { value: 'Em Andamento', color: 'rgba(255,222,89,1)', label: this.literals.inProgress },
          { value: 'Em Aprovação', color: 'rgba(0,151,178,1)', label: this.literals.inApproval },
          { value: 'Aprovado', color: 'rgba(112,173,223,1)', label: this.literals.approved },
          { value: 'Cancelado', color: 'rgba(255,145,77,1)', label: this.literals.canceled },
          { value: 'Finalizado', color: 'rgba(255,49,49,1)', label: this.literals.completed },
          { value: 'SUSPENDO', color: 'rgba(255,49,49,1)', label: this.literals.suspended },
          { value: 'INVIAVEL', color: 'rgba(255,49,49,1)', label: this.literals.unfeasible },
          { value: 'Revisão', color: 'rgba(255,49,49,1)', label: this.literals.revision }
        ]},
      { label: this.literals.requestCode, property: 'DOCUMENTID',visible:false },
      { 
        label: this.literals.action, 
        property: 'NUM_SEQ_ESTADO',
        width:'5%',
        type: 'icon', 
        icons: [
          { 
            value: 'Em Andamento', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest,  
            action: this.extras.bind(this),
          },{ 
            value: 'Em Aprovação', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest,
            action: this.extras.bind(this), 
          },{ 
            value: 'Aprovado', 
            color: 'color-12' , 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest  
          },{ 
            value: 'Cancelado', 
            color: 'color-07', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest  
          },{ 
            value: 'Finalizado', 
            color: 'color-07',
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewRequest 
          }
      ]
      },
    ]
  }
}
