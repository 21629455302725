import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;
  async getDatas(documentId: number) {

    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_FRM_GESTAO_DA_EXPORTACAO",
      constraints: [{
        _field: 'documentId',
        _initialValue: documentId,
        _finalValue: documentId,
        _type: 1
      }]
    };

    const httpOptions = this.authenticator.connect(requestData)

    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: this.addColumnStatus(response.content.values),
            totalHits: response.content.values.length
          });
        }
      );
    });
  }


  private addColumnStatus(valores: []) {
    valores.map((item: any) => {
      item['status'] = 'onApproval'
    })
    return valores
  }

  moveRequest(documentId: number, aprovOrReprov: string, oReproved?: any, justificativa?: any, observacao?: any) {
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };


    const body = {
      name: "DTS_SAVE_DATA_IN_THE_FIELD",
      constraints: [{
        _field: 'documentId',
        _initialValue: documentId,
        _finalValue: documentId,
        _type: 1
      }, {
        _field: 'move_valida_draft',
        _initialValue: aprovOrReprov,
        _finalValue: aprovOrReprov,
        _type: 1
      }, {
        _field: 'move_declara_prod',
        _initialValue: ' ',
        _finalValue: ' ',
        _type: 1
      }
      ]
    };

    if (oReproved.length > 0) {
      body.constraints.push({
        _field: "reprov_draft",
        _initialValue: JSON.stringify(oReproved),
        _finalValue: JSON.stringify(oReproved),
        _type: 1
      })
    }

    if (justificativa != '') {
      body.constraints.push({
        _field: "justificativa_draft",
        _initialValue: justificativa,
        _finalValue: justificativa,
        _type: 1
      })
    }
    
    if (observacao != '') {
      body.constraints.push({
        _field: "justificativa_draft",
        _initialValue: observacao,
        _finalValue: observacao,
        _type: 1
      })
    }

    const httpOptions = this.authenticator.connect(requestData)

    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values,
            totalHits: response.content.values.length
          });
        }
      );
    });
  }

  async getFiles(id: number) {
    console.log("Id: ", id);
    let { response }: any = await this.getListFolder('Emissão - Invoice e Packing List', undefined) as { DESCRIPTION: string, ID: number };
    const value: any = await this.getListFolder(undefined, response[0].ID)
    const result = value.response.filter((val: any) => val.ID == id)
    const files = await this.getListFolder(undefined, result[0].ID)
    const data = await this.getUrl(files)

    return data
  }

  async getListFolder(nameFolder?: string, idFolder?: number) {
    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_LISTFOLDER",
      constraints: [] as any
    }

    if (nameFolder) {
      body.constraints.push({
        _field: "NAME_FOLDER",
        _initialValue: nameFolder,
        _finalValue: nameFolder,
        _type: 1
      })
    }

    if (idFolder) {
      body.constraints.push({
        _field: "ID_FOLDER",
        _initialValue: idFolder,
        _finalValue: idFolder,
        _type: 1
      })
    }

    const httpOptions = this.authenticator.connect(requestData)

    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values,
            totalHits: response.content.values.length
          });
        },
        (error: any) => {
          console.log("Erro ao retornar Dataset: ", error)
        }
      );
    });
  }

  async getUrl(listFiles?: any) {
    const { response } = listFiles

    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`


    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "DTS_GET_URL",
      constraints: [{
        _field: "LIST_FILES",
        _initialValue: JSON.stringify(response),
        _finalValue: JSON.stringify(response),
        _type: 1
      }]
    }


    const httpOptions = this.authenticator.connect(requestData)

    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values,
            totalHits: response.content.values.length
          });
        }
      );
    });
  }

  async getListFolderCertOrig(numProces: any) {

    this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`

    const requestData = {
      url: this.apiUrl,
      method: 'POST'
    };

    const body = {
      name: "ds_process_attachments_files",
      constraints: [] as any
    }

    body.constraints.push({
      _field: "processid",
      _initialValue: numProces,
      _finalValue: numProces,
      _type: 1
    })

    const httpOptions = this.authenticator.connect(requestData)

    return new Promise((resolvePromise) => {
      this.http.post(requestData.url, body, httpOptions).subscribe(
        (response: any) => {
          resolvePromise({
            response: response.content.values,
            totalHits: response.content.values.length
          });
        },
        (error: any) => {
          console.log("Erro ao retornar Dataset: ", error)
        }
      );
    });

  }
}
