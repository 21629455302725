import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from './documents.service';
import { PoNotificationService, PoTableColumn, PoTableComponent, PoToasterOrientation, PoUploadComponent } from '@po-ui/ng-components';
import { TranslationService } from 'src/app/services/i18n/translation.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  @ViewChild(PoTableComponent, { static: true }) poTable!: PoTableComponent;
  @ViewChild('upload') upload!: PoUploadComponent;

  literals: any = {};
  isEdit: boolean = true;
  isLoading: boolean = true;
  isLoadingFiles = true;
  activity: number = 0;
  isReprovado: boolean = false;
  isAprovado: boolean = false;
  gridItems: any = [];
  gridColumns: Array<PoTableColumn> = [];
  gridItemsFiles: any = [];
  gridColumnsFiles: Array<PoTableColumn> = [];
  idFolder: number = 0;
  justificativa: string = '';
  justificativaDraft: string = '';
  observa_cert_orig: string = '';
  observacao: string = '';
  documentId!: number;
  obsRegistrarDeclaraProduto: string = '';
  numProcesso: any;

  constructor(
    private service: DocumentsService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    public notification: PoNotificationService,
    private translationService: TranslationService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      let id = params.get('id');

      this.documentId = id ? Number(id) : 0;
      this.loadLiterals();
    });

    this.activatedRoute.queryParamMap.subscribe(params => {
      const editParam = params.get('edit');
      this.activity = Number(params.get('activity'));
      this.isEdit = editParam === 'true' ? true : false;

      this.gridColumns.forEach(item => {
        if (item.property == 'status' || item.property == 'nameFile' && this.activity != 84 || item.property == 'includeAnexo' && this.activity != 84) {
          item['visible'] = this.isEdit
        }
      })
    });


    this.service.getDatas(this.documentId).then(({ response }: any) => {
      let count: number = 0
      const { justificativa_draft, obs_declaracao_prod, idFolderNF, numProcesso, observa_cert_orig } = response[0]
      this.numProcesso = numProcesso;
      this.justificativaDraft = justificativa_draft;
      this.obsRegistrarDeclaraProduto = obs_declaracao_prod == null ? '' : obs_declaracao_prod.trim();
      this.observa_cert_orig = observa_cert_orig;
      console.log(observa_cert_orig)
      response.forEach((value: any)=>{
        value.data_solic = value.data_solic.replaceAll("-", "/");
        value.data_valida_invoice = value.data_valida_invoice.split(" ")[0];
      })
      this.gridItems = response;

      this.service.getListFolderCertOrig(numProcesso)
        .then(({ response }: any) => {
          let justCert = this.filtrarCertificadoOrigem(response);
          this.gridItemsFiles = justCert;
          this.isLoadingFiles = false;
        })
        .catch(
          () => this.isLoadingFiles = false
        )

      

      this.loadLiterals();
    })
  }

  loadLiterals() {
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }

  toggleDetails(rowItem: any, rowIndex: number) {
    let arrowDownButton: any = document.getElementsByClassName("po-icon-arrow-down");
    let arrowUpButton: any = document.getElementsByClassName("po-icon-arrow-up");

    if (arrowDownButton.length > 0) {
      arrowDownButton[0].click();
    }
    else {
      arrowUpButton[0].click();
    }
  }

  filtrarCertificadoOrigem(documentos: any) {
    let docArray: any[] = [];
    let result = documentos.find((doc: any) => doc.fileName.includes("Certificado Origem"));

    if(result){
      docArray.push(result);
      docArray.forEach((doc: any) => {
        doc.isUrl = 'true';
      });
    }

    return docArray
  }

  approveOrReprovedItems(value: string) {
    let selectedItems: any;
    this.isReprovado = false;
    this.isAprovado = false;
    selectedItems = this.poTable.getSelectedRows()

    if (value == 'toApprove') {

      selectedItems.map((val: any) => {
        val.status = 'approved'
      })

      this.poTable.unselectRows()
    } else {
      selectedItems.map((val: any) => {
        val.status = 'disapproved';
      });
      this.poTable.unselectRows()
    }

    this.gridItems.forEach((item: any) => {
      if (item.status == "disapproved") {
        this.isReprovado = true;
      }
      else {
        this.isAprovado = true;
      }
    })

    this.gridItems.some((item: any) => {
      if (item.status == "disapproved" || item.status == "onApproval") {
        this.isAprovado = false;
      }
    })
  }

  opeAnexos(row: any) {
    window.open(row?.downloadUrl, '_blank');
    this.route.navigate
  }


  async validLines() {
    const aReproved: Array<any> = [];
    this.isLoading = true;
    const isOnApproval = this.gridItems.some((item: any) => item.status == "onApproval");
    const status = this.gridItems.some((item: any) => item.status == "disapproved") ? "reprovado" : "aprovado";

    if (isOnApproval) {
      this.notification.error({
        message: `Para realizar o envio os itens devem ser aprovados ou reprovados.`,
        duration: 4000,
        orientation: PoToasterOrientation.Top
      });
      return
    }


    if (status == "reprovado") {
      if (this.justificativa == '') {
        this.notification.error({
          message: `Campo de Justificativa deve ser preenchido.`,
          duration: 4000,
          orientation: PoToasterOrientation.Top
        });
        return
      } else {
        this.gridItems.forEach((item: any) => {
          const itemReproved: any = {}
          itemReproved[item.ov_cliente] = item.cod_prod
          aReproved.push(itemReproved)
        });
      }
    }



    const { response }: any = await this.service.moveRequest(
      this.documentId,
      status,
      aReproved,
      this.justificativa,
      this.observacao
    )
    const { STATUS } = response[0]

    if (STATUS == 'ok') {
      this.notification.success({
        message: `Sucesso`,
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      this.route.navigate(['consultardocumentos'])
    } else {
      this.notification.error({
        message: 'Erro ao realizar a movimentação da solicitação',
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
    }

  }

  loadValues() {
    this.gridColumns = [
      { label: this.literals.solicitationNumber, property: 'numProcesso' },
      { label: this.literals.openingDate, property: 'data_solic'},
      { label: this.literals.responseDeadline, property: 'data_valida_invoice'},
      { label: this.literals.shipmentNumber, property: 'recebeRemessa', visible: false },
      { label: this.literals.grossWeight, property: 'pesoBruto', visible: false },
      {
        label: this.literals.viewDocuments,
        property: 'status', //Valor aleatorio
        type: 'icon',
        icons: [
          {
            value: 'onApproval', //Valor aleatorio
            icon: 'po-icon-eye',
            tooltip: 'Exibe os Documentos',
            action: this.toggleDetails.bind(this),
          }
        ],
      },
      {
        property: 'status',
        type: 'label',
        labels: [
          {
            value: "onApproval",
            color: 'color-08',
            label: this.literals.inApproval
          }, {
            value: 'approved',
            color: 'color-11',
            label: this.literals.approved,
            textColor: 'white'
          }, {
            value: 'disapproved',
            color: 'color-07',
            label: this.literals.rejected,
            textColor: 'white'
          }
        ]
      }
    ]

    /*this.gridColumnsFiles = [
      { label: this.literals.documentName, property: 'DESCRIPTION' },
      { label: this.literals.id, property: 'ID_DOCUMENT', visible: false },
      { label: this.literals.url, property: 'URL', visible: false },
      {
        label: this.literals.viewAttachment,
        property: 'isUrl',
        width: '5%',
        type: 'icon',
        icons: [
          {
            value: 'true',
            icon: 'po-icon-eye',
            tooltip: this.literals.viewAttachment,
            action: this.opeAnexos.bind(this),
          }
        ]
      }
    ]*/
    
    this.gridColumnsFiles = [
      { label: this.literals.documentName, property: 'fileName' },
      { label: this.literals.id, property: 'documentId', visible: false },
      { label: this.literals.url, property: 'downloadUrl', visible: false },
      {
        label: this.literals.viewAttachment,
        property: 'isUrl',
        width: '5%',
        type: 'icon',
        icons: [
          {
            value: 'true',
            icon: 'po-icon-eye',
            tooltip: this.literals.viewAttachment,
            action: this.opeAnexos.bind(this),
          }
        ]
      }
    ]
  }

}
