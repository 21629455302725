import { Component } from '@angular/core';
import { PoTableColumn, PoTagType } from '@po-ui/ng-components';
import { Router } from '@angular/router';
import { ListDocumentsService } from './list-documents.service';
import { TranslationService } from 'src/app/services/i18n/translation.service';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.css']
})
export class ListDocumentsComponent {
  columns: Array<PoTableColumn> = [];
  columns2: Array<PoTableColumn> = [];
  items: Array<any> = [];
  literals: any = {};

  constructor(
    private service: ListDocumentsService,
    private route: Router,
    private translationService: TranslationService
  ){}

  async ngOnInit() {
    const {response}:any = await this.service.getDatas()
    this.items = response

    this.loadLiterals();
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }

  opeAnexos(row: any) {
    window.open(row?.URL, '_blank');
      this.route.navigate
  }

  loadValues(){
    this.columns = [
      { property: 'PROCESS_ID', label: this.literals.solicitationNumber, type: 'number',width:'15%',},
      { property: 'DATA_ABERTURA', label: this.literals.openingDate, type: 'date'},
    ];

    this.columns2 = [
      { 
        label: this.literals.viewAttachment, 
        property: 'iS_URL',
        type: 'icon', 
        icons: [
          { 
            value: 'true', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewAttachment,  
            action: this.opeAnexos.bind(this),
          }
        ]
      },
      { property: 'NOME_ARQUIVO',label: this.literals.documentName},
    ];

  }
}
