export const generalPt = {
    hello: 'Ola',
    welcome: 'Bem-vindo ao Portal do Cliente',
    solicitationNumber: 'Nº Solicitação',
    openingDate: 'Data de Abertura',
    approvalDeadline: 'Prazo Aprovação',
    documentValidation: 'Validação Documentos', 
    status: 'Status',
    validateProductDeclaration: 'Validar Declaração de Produto',
    validateOriginCertificate: 'Validar Certificado de Origem',
    approveDraft: 'Validar Documentos',
    requestCompleted: 'Solicitação Finalizada',
    inProgress: 'Em Andamento',
    inApprovalByComex: 'Em Aprovação por Comex',
    clearFilters: 'Limpar Filtros',
    selectItems: "Selecionar Itens",
    search: "Buscar",
    client: "Cliente",
    inApproval: "Em Aprovação",
    approved: "Aprovado",
    rejected: "Reprovado",
    country: "País",
    productCode: "Codigo Produto",
    productDescription: "Descrição Produto",
    customerSalesOrder: "OV. Cliente",
    productSalesOrder: "OV. Produto",
    productOrderNumber: "Nº Ordem de Produto",
    shipmentDate: "Data da Remessa",
    url: "Url",
    viewAttachment: "Visualizar anexo",
    back: "Voltar",
    processNumber: "Nº Processo",
    shipmentNumber: "Nº Remessa",   
    approveRequest: "Aprovar Solicitação",
    viewRequest: "Visualizar Solicitação",
    isOnapprovalError: "Para realizar o envio os itens devem ser aprovados ou reprovados.",
    justificationError: "Campo de Justificativa deve ser preenchido.",
    approve: "Aprovar",
    reject: "Reprovar",  
    submit: "Enviar",
    success: "Sucesso",
    ErroWhilePerforming: "Erro ao realizar a",
    approval: "Aprovação",
    rejection: "Reprovação",
    justifyRejection: "Justificar Reprovação",
    itemsAttachedProcess: "Itens Anexados ao Processo",
    responseDeadline: "Prazo de Resposta",
    grossWeight: "Peso Bruto",
    documentName: "Nome do Documento",
    id: "ID",
    commercialProposalNumber: "Nº Proposta Comercial",
    revisionNumber: "Nº Revisão",
    projectName: "Nome Projeto",
    quotationStartDate: "Data início da Cotação", 
    deliveryDeadline: "Prazo de Entrega",
    inApprovalSince: "Em aprovação Desde",
    inReview: "Em Análise",
    canceled: "Cancelado",
    completed: "Finalizado",
    suspended: "Suspendo",
    unfeasible: "Inviável",
    revision: "Revisão",
    requestCode: "Código da Solicitação",
    action: "Ação",
    theFields: "Os campos",
    mustBeFilled: "devem ser preenchidos para avançar para a próxima atividade. Preencha-os!",
    RFQOpening: "Abertura de RFQ",
    newRFQQuestion: "Nova RFQ?*:",
    newRFQ: "Nova RFQ",
    forecast: "Forecast",
    observation: "Observação",
    viewDocuments: "Visualizar Documentos"










}