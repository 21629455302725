import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ListInvoicePackingService {

 

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;




  async getDatas(filters?:any) {

      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
  
      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_LIST_INVOICE",
        constraints:[{
          _field: "SEQUENCE", 
          _initialValue: '35', 
          _finalValue: '35', 
          _type: 1
        }]
      };
  
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: this.addColumnStatus(response.content.values),
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    private addColumnStatus(valores:[]){
      valores.map((item:any) => {
        item['status'] = 'onApproval'
      })
      return  valores
    }


}
