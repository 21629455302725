import { Component, OnInit } from '@angular/core';
import { EditInvoicePackingService } from './edit-invoice-packing.service'
import { PoNotificationService, PoTableColumn, PoToasterOrientation } from '@po-ui/ng-components';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/services/i18n/translation.service';

@Component({
  selector: 'app-edit-invoice-packing',
  templateUrl: './edit-invoice-packing.component.html',
  styleUrls: ['./edit-invoice-packing.component.css']
})
export class EditInvoicePackingComponent implements OnInit{

  literals: any = {};
  isLoading = true;
  isLoadingFiles = true;
  isReproved:boolean = false;
  isApproved:boolean = false;
  campoJustificativa: string = '';
  observacao:string = '';
  documentId:number = 0;
  idFolder:number = 0;
  gridItemsFiles: any[] = [];
  gridItems: Array<any> = [];
  gridColumns:Array<PoTableColumn> = [];
  gridColumnsFiles:Array<PoTableColumn> = [];
  dataAbertura: any;

  constructor(
    public service: EditInvoicePackingService,  
    public notification: PoNotificationService,  
    private activatedRoute : ActivatedRoute,
    private router: Router,
    private translationService: TranslationService
  ){}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.dataAbertura = params['d'];
    });
    this.activatedRoute.paramMap.subscribe(params => {
      this.documentId = Number(params.get('id'));
    });

    this.service.getDataGrid(this.documentId)
      .then(({ response }: any) => {
        this.isLoading = true;
        this.gridItems = response;
        const {idFolderNF} = response[0];
        this.isLoading = false;
        this.idFolder = idFolderNF;
        
        this.gridItems[0].data_abertura = this.dataAbertura;
        console.log(this.gridItems[0])

        this.service.getFiles(this.idFolder)
          .then(({ response }: any)=> {
            this.gridItemsFiles = response;
            this.isLoadingFiles = false
          })
          .catch(
            ()=> this.isLoadingFiles = false
          )

        this.loadLiterals();
      })   
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }

  toggleDetails(rowItem: any, rowIndex: number) {
    let arrowDownButton: any = document.getElementsByClassName("po-icon-arrow-down");
    let arrowUpButton: any = document.getElementsByClassName("po-icon-arrow-up"); 
    
    if(arrowDownButton.length > 0){
      arrowDownButton[0].click();
    }
    else{
      arrowUpButton[0].click();
    } 
  }

  handleApprovedOrReproved(decision:string) {
    if(decision == 'approved'){
      this.gridItems.map((val:any) => {
        val.status = 'approved'
      })
      this.isReproved = false;
      this.isApproved = true;
      this.campoJustificativa = '';
    }else{
      this.isReproved = true;
      this.isApproved = false;
      this.gridItems.map((val:any) => {
        val.status = 'disapproved';
      })
    }
  }

  opeAnexos(row: any) {
    window.open(row?.URL, '_blank');
  }

  async handleSave(){
    const isOnApproval = this.gridItems.some((item:any) => item.status == "onApproval")
    
    if(isOnApproval){
      this.notification.error({ 
        message: this.literals.isOnapprovalError, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
    });
      return 
    }

    if(this.isReproved && this.campoJustificativa == ""){
      this.notification.error({ 
        message: this.literals.justificationError, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
      return 
    }

    const isReproved = this.isReproved ? 'reprovado' : 'aprovado';
    const {response}:any = await this.service.moveRequest(
      this.documentId,      
      isReproved,
      this.campoJustificativa,
      this.observacao
    )

    if(response[0].STATUS == 'ok'){
      this.notification.success({ 
        message: this.literals.success, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
      this.router.navigate(['/consultainvoice'])
    }else{
      this.notification.warning({ 
        message: `${this.literals.ErroWhilePerforming} ${isReproved == 'aprovado' ? this.literals.approval : this.literals.rejection}`, 
        duration: 4000,
        orientation: PoToasterOrientation.Top
      }); 
    }
    
  }

  loadValues(){
    this.gridColumns = [   
      { label: this.literals.solicitationNumber, property: 'numProcesso'},
      { label: this.literals.openingDate, property: 'data_abertura', type: 'date'},
      { label: this.literals.responseDeadline, property: 'data_valida_invoice'},
      { label: this.literals.shipmentNumber, property: 'recebeRemessa', visible: false},
      { label: this.literals.grossWeight, property: 'pesoBruto', visible: false},
      { 
        label: this.literals.viewDocuments,
        property: 'status', //Valor aleatorio
        type: 'icon',
        icons: [
          {
            value: 'onApproval', //Valor aleatorio
            icon: 'po-icon-eye',
            tooltip: 'Exibe os Documentos',
            action: this.toggleDetails.bind(this),
          }
        ],
      },
      {
        property: 'status',
        type: 'label',
        labels: [
          { 
            value: "onApproval",
            color: 'color-08',
            label: this.literals.inApproval
          },{ 
            value: 'approved', 
            color: 'color-11', 
            label: this.literals.approved,
            textColor:'white'
          },{ 
            value: 'disapproved', 
            color: 'color-07', 
            label: this.literals.rejected,
            textColor:'white' 
          }
        ]
      }
    ]

    this.gridColumnsFiles = [ 
      { label: this.literals.documentName, property: 'DESCRIPTION'},
      { label: this.literals.id, property: 'ID_DOCUMENT',visible:false},
      { label: this.literals.url, property: 'URL',visible:false},
      { 
        label: this.literals.viewAttachment, 
        property: 'isUrl',
        width:'5%',
        type: 'icon', 
        icons: [
          { 
            value: 'true', 
            icon: 'po-icon-eye', 
            tooltip: this.literals.viewAttachment,  
            action: this.opeAnexos.bind(this),
          }
        ]
      }
    ]
  }
}
