<po-widget>

  <div class="po-row container-bread">
    <div class="po-col-12 po-md-6">
      <po-breadcrumb [p-items]="[
          { label: 'Documentos', link: '/consultardocumentos' },
          { label: 'Validação Documentos', link: '' }
      ]">
      </po-breadcrumb>
    </div>
  </div>

  <po-widget [p-primary]="true" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngIf="observa_cert_orig">
    <div style="display: flex;align-items: center;">
      <span class="po-icon po-icon-info" style="margin-right:15px;color:blue"></span>
        <ng-container>
          <span>Observação: <strong>{{observa_cert_orig}}</strong></span>
        </ng-container>
    </div>
  </po-widget>

  <po-table [p-auto-collapse]="true" [p-columns]="gridColumns" [p-hide-columns-manager]="true" [p-items]="gridItems"
    [p-selectable]="true" [p-sort]="true" [p-striped]="true">
    <ng-template p-table-row-template let-rowItem let-i="rowIndex">
      <po-widget p-title="Itens Anexados ao Processo">
        <po-table style="width: 100%;" [p-columns]="gridColumnsFiles" [p-items]="gridItemsFiles" [p-sort]="true"
          [p-hide-columns-manager]="true" [p-loading]="isLoadingFiles">
        </po-table>
      </po-widget>
    </ng-template>
  </po-table>

  <div class="po-row">
    <div class="po-md-12" *ngIf="isReprovado" style="margin-top: 15px;">
      <po-textarea class="po-md-12" p-label="Justificativar Reprovação*" [p-required]="true" [p-show-required]="false"
        [(ngModel)]="justificativa"></po-textarea>
    </div>
    <div class="po-md-12" *ngIf="isAprovado" style="margin-top: 15px;">
      <po-textarea class="po-md-12" p-label="Observação" [(ngModel)]="observacao"></po-textarea>
    </div>
  </div>

  <div class="po-row" *ngIf="isEdit">
    <po-button class="po-md-6" p-icon="po-icon po-icon-close" (p-click)="approveOrReprovedItems('disapproved')"
      [p-danger]="true" p-label="Reprovar">
    </po-button>
    <po-button class="po-md-6" p-icon="po-icon po-icon-ok" (p-click)="approveOrReprovedItems('toApprove')"
      p-label="Aprovar">
    </po-button>
  </div>

  <div class="po-row" style="margin-top: 15px;display: flex;justify-content: end;" *ngIf="isEdit">
    <po-button class="po-md-1" p-label="Enviar" (p-click)="validLines()"></po-button>
  </div>
</po-widget>