export const generalEn = {
    hello: 'Hello',
    welcome: 'Welcome to the Customer Portal',
    solicitationNumber: 'Solicitation Number',
    openingDate: 'Opening Date',
    approvalDeadline: 'Approval Deadline',
    documentValidation: "Document Validation",  
    status: "Status",  
    validateProductDeclaration: "Validate Product Declaration",  
    validateOriginCertificate: 'Validate Origin Certificate',
    approveDraft: "Approve Draft",  
    requestCompleted: "Request Completed",  
    inProgress: "In Progress",  
    inApprovalByComex: "In Approval by Comex",
    clearFilters: 'Clear Filters',
    selectItems: "Select Items",
    search: "Search",
    client: "Client",
    inApproval: "In Approval",
    approved: "Approved",
    rejected: "Rejected",
    country: "Country",
    productCode: "Product Code",
    productDescription: "Product Description",
    customerSalesOrder: "Customer Sales Order",
    productSalesOrder: "Product Sales Order",
    productOrderNumber: "Product Order Number",
    shipmentDate: "Shipment Date",
    url: "Url",
    viewAttachment: "View Attachment",
    back: "Back",
    processNumber: "Process Number",
    shipmentNumber: "Shipment Number",
    approveRequest: "Approve Request",
    viewRequest: "View Request",
    isOnapprovalError: "To proceed with the shipment, the items must be approved or rejected.",
    justificationError: "Justification field must be filled out.",
    approve: "Approve",
    reject: "Reject",  
    submit: "Submit",
    success: "Success",
    ErroWhilePerforming: "Error while performing the",
    approval: "Approval",
    rejection: "Rejection",
    justifyRejection: "Justify Rejection",
    itemsAttachedProcess: "Items Attached to the Process",
    responseDeadline: "Response Deadline",
    grossWeight: "Gross Weight",
    documentName: "Document Name",
    id: "ID",  
    commercialProposalNumber: "Commercial Proposal Number",
    revisionNumber: "Revision Number",
    projectName: "Project Name",
    quotationStartDate: "Quotation Start Date",
    deliveryDeadline: "Delivery Deadline",
    inApprovalSince: "In Approval Since",
    inReview: "In Review",
    canceled: "Canceled",
    completed: "Completed",
    suspended: "Suspended",
    unfeasible: "Unfeasible",
    revision: "Revision",
    requestCode: "Request Code",
    action: "Action",
    theFields: "The fields",
    mustBeFilled: "must be filled out to proceed to the next activity. Please fill them out!",
    RFQOpening: "RFQ Opening",
    newRFQQuestion: "New RFQ?*:",
    newRFQ: "New RFQ",
    forecast: "Forecast",
    observation: "Observation",
    viewDocuments: "View Documents",














}
