import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,FormArray,CheckboxControlValueAccessor } from '@angular/forms'
import { SharedService } from '../../../shared/shared.service'
import { UtilsService } from '../../../services/utils.service';
import {  inputsDocuments } from './documentos.interface';
import { of } from 'rxjs';
import { PoDynamicFormField } from '@po-ui/ng-components';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  documentoForm!: FormGroup;
  inDocumentos = new inputsDocuments();
  itensDocumentos: any[] = [];
  itensCamposDocumentos: any = [];
  checkboxGroup!: any;
  base64Output!: string;
  isViewInput:any = false;
  model!: any
  count: number = 0
  inputs: any = []
  valueInputs: any = {};
  aInputs = [{
    property: `produto_documentos`,
    label: 'Produto',
    required: true,
    disabled: true,
  },
  {
    divider: 'Documentos Essenciais',
    property: `doc_obri_cb1`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb1',
        label: 'BOM (Com indicação de part numer e AVL**). Para revisões, quando não fornecida a BOM, a Hi-Mix utilizará a versão disponível em seus arquivos.'
      }
    ]
  },
  {
    property: `doc_obri_cb2`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb2',
        label: 'Arquivo Gerber.'
      }
    ]
  },
  {
    property: `doc_obri_cb3`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb3',
        label: 'Informações do MDA/ICT. Lista de cobertura, especificações, e programa de testes para placas e/ou produto acabado.'
      }
    ]
  },
  {
    property: `doc_obri_cb4`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb4',
        label: 'Relação completa dos itens que não devem ser orçados e/ou montados, quando aplicável.'
      }
    ]
  },
  {
    property: `doc_obri_cb5`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb5',
        label: 'Relação completa dos itens que serão fornecidos pelo cliente, quando aplicável.'
      }
    ]
  },
  {
    property: `doc_obri_cb6`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb6',
        label: 'Relação de itens negociados diretamente entre cliente e fornecedor, quando aplicável.(Regra do Box campo fabricante)'
      }
    ]
  },
  {
    property: `doc_obri_cb7`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_obri_cb7',
        label: 'Desenhos técnicos (cabos, trafos, itens mecânicos,etc), quando aplicável.'
      }
    ]
  },
  {
    divider: 'Documentos Opcionais',
    property: `doc_op_cb1`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb1',
        label: 'Relação de itens com características especiais e como são identificados.' 
      }
    ]
  },{
    property: `doc_op_cb2`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb2',
        label: 'Foto do painel ou placa montada. Indicar se há placas-filhas.' 
      }
    ]
  },{
    property: `doc_op_cb3`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb3',
        label: 'Placas-golden suficientes para debug de engenharia e cotação.' 
      }
    ]
  },{
    property: `doc_op_cb4`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb4',
        label: 'Fornecer a instrução de montagem (SOP) ou informar os tempos de montagem a serem considerados no orçamento.' 
      }
    ]
  },{
    property: `doc_op_cb5`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb5',
        label: 'Informações sobre as jigas para teste funcional (layout com foto e descrição). Tempo estimado de teste por painel. Requisitos de teste run-in e burn-in.' 
      }
    ]
  },{
    property: `doc_op_cb6`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb6',
        label: 'Requisitos de rastreabilidade - chão-de-fábrica (finished good, RMA).' 
      }
    ]
  },{
    property: `doc_op_cb7`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb7',
        label: 'Regras de etiquetagem (para placa e produto acabado).' 
      }
    ]
  },{
    property: `doc_op_cb8`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb8',
        label: 'Critérios cosméticos e de abertura entre peças plásticas e materiais correlatos.' 
      }
    ]
  },{
    property: `doc_op_cb9`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb9',
        label: 'Se há algum tipo de equipamento de teste/tooling que deverá ser desenvolvido ou fornecido pela Hi-Mix (FCT/ICT/outros), enviar arquivos com a descrição completa desses equipamentos de teste e tooling, como gabaritos, pallets, etc, estimando o tempo de vida útil dos mesmos.' 
      }
    ]
  },{
    property: `doc_op_cb10`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb10',
        label: 'Especificação para embalagem individual, embalagem coletiva, paletes, manuais e guias disponíveis para cotação.' 
      }
    ]
  },{
    property: `doc_op_cb11`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb11',
        label: 'Se necessário algum tipo de treinamento ao pessoal Hi-Mix enviará um arquivo detalhado sobre o treinamento.' 
      }
    ]
  },{
    property: `doc_op_cb12`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb12',
        label: 'Se necessário o desenvolvimento de algum RMA plan enviar arquivo detalhado.' 
      }
    ]
  },{
    property: `doc_op_cb13`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb13',
        label: 'Se necessário o desenvolvimento de algum quality plan (Yield / DPPM / NCM / RR / DOA / etc) enviar arquivo detalhado.' 
      }
    ]
  },{
    property: `doc_op_cb14`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb14',
        label: 'Se necessário gerenciar o PPAP de Fornecedor enviar arquivos detalhados.' 
      }
    ]
  },{
    property: `doc_op_cb15`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb15',
        label: 'No caso de itens críticos, enviar documento com método de controle e período de retenção.' 
      }
    ]
  },{
    property: `doc_op_cb16`,
    label:'',
    optionsMulti: true,
    gridColumns: 12,
    gridSmColumns: 12,
    options: [
      {
        value: 'doc_op_cb16',
        label: 'Desenhos técnicos (cabos, trafos, itens mecânicos,etc), quando aplicável.'
      }
    ]
  }
]



  constructor(
    private sharedService : SharedService,
    private utilsService: UtilsService,
  ) { }

  async ngOnInit() {
    
    this.sharedService.productsRevisao$.subscribe((value: any)=> {
      if(value.length > 0){
        value[0].forEach((val:any) => {
          this.itensDocumentos.push(val)
          })
        
          this.itensCamposDocumentos = value[1]
          this.buildInputs()
      }else{
        this.sharedService.curentProduct$.subscribe((values: any)=> {
          for (const value of values) {
            this.count++
            this.aInputs.map(val => {    
              let valCopy = {...val};
              valCopy.property = `${valCopy.property}___${this.count}`
              this.inputs.push(valCopy)
              
              if(val.property == 'produto_documentos'){
                this.valueInputs[`${valCopy.property}`] = value
              }
            }) 
          }
        })
      }
    })
    
    this.isViewInput = this.sharedService.getIsView
    
    // if(this.inputs){
    //   this.inputs = this.aInputs
    // }
  }

//   onCheckboxChange(newValues: string[]): void {
//     this.checkboxValues = newValues;
//   }

  onFileSelected(event :any) {
    this.utilsService.convertFile(event.file.rawFile).subscribe(base64 => {
      this.base64Output = base64;

      this.sharedService.postDadosFiles([{
        base64,
        name:event.file.name
      }])
    });
  }

  async buildInputs(){
    for (const value of this.itensDocumentos) {
      this.count++
      this.aInputs.map(val => {    
        let valCopy = {...val};
        valCopy.property = `${valCopy.property}___${this.count}`
        this.inputs.push(valCopy)
        
        if(value[val.property] == 'on'){
          this.valueInputs[`${valCopy.property}`] = [val.property]
        }else{
          this.valueInputs[`${valCopy.property}`] = value[val.property]
        }
      }) 
    }
  }
}
