import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Idocument} from './list-documents.interface'
import { AuthenticatorService } from 'src/app/services/authenticator.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ListDocumentsService {

  constructor(
    private http: HttpClient,
    private authenticator: AuthenticatorService
  ) { }

  apiUrl: any;




  async getDatas(filters?:any) {    
  
      this.apiUrl = `${environment.url}/api/public/ecm/dataset/datasets`
    
  
      const requestData = {
        url: this.apiUrl,
        method: 'POST'
      };
  
      const body = {
        name: "DTS_LIST_DOCUMENTS_CERTIFI",
        constraints:[]
      };
  
      const httpOptions = this.authenticator.connect(requestData)
      
      return new Promise((resolvePromise) => {
        this.http.post(requestData.url, body, httpOptions).subscribe(
          (response: any) => {
            resolvePromise({
              response: this.prepareJson(response.content.values),
              totalHits: response.content.values.length
            });
          }
        );
      });
    }

    prepareJson(values:Array<any>){
      let processId:number = 0;
      let result:any = [];

      values.forEach((value:Idocument) => {
        if (value.PROCESS_ID) {
            processId = value.PROCESS_ID
            result.push({
              DATA_ABERTURA: value.DATA_ABERTURA,
              DOCUMENT_ID: value.DOCUMENT_ID,
              NUM_ATIV: value.NUM_ATIV,
              PROCESS_ID: value.PROCESS_ID,
              ATTACHMENT: [{
                  NOME_ARQUIVO: value.NOME_ARQUIVO, 
                  URL:value.URL,
                  iS_URL:'true'
              }]
          });
        }else{
          const index = result.findIndex((val:Idocument) => val.PROCESS_ID == processId)
           result[index].ATTACHMENT.push({
            NOME_ARQUIVO:value.NOME_ARQUIVO, 
            URL: value.URL,
            iS_URL:'true'
          }) 
        }
      });

      return result

    }
}
