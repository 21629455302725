import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms'
import {RegisterService} from './register.service'
import { PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';
import { UtilsService } from '../../../services/utils.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  


  constructor(
    public notification: PoNotificationService,
    private service: RegisterService,
    private utilsService: UtilsService,
    private router: Router,
    
  ) { }

  registerForm!: FormGroup
  registered: boolean = false
  isCNPJ: boolean = false
  isRequired: boolean = false

  ngOnInit(): void {
   

    this.registerForm = new FormGroup({
      nome: new FormControl('', [Validators.required]),
      sobrenome: new FormControl('', [Validators.required]),
      cnpj: new FormControl('', this.isRequired ? [Validators.required] : []),
      nomeEmpresa: new FormControl('', this.isRequired ? [Validators.required] : []),
      email_user: new FormControl('', this.isRequired ? [Validators.required] : [])
    });
  }

  register(){
    const {value,status} = this.registerForm

    if(status == 'INVALID'){
      this.notification.error({ 
        message: `Para prosseguir todos os campos devem ser preenchidos.`, 
        duration: 3000,
        orientation: PoToasterOrientation.Top
      })
    }

    this.service.registerUser(value).then(({ response }: any)=> {
      if(response){
        this.notification.success({ 
          message: `Cadastro realizado com sucesso!`, 
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });

        this.router.navigate(["/login"])
      }else{
        this.notification.warning({ 
          message: `Erro ao realizar cadastro!`, 
          duration: 3000,
          orientation: PoToasterOrientation.Top
        });
      }
    })
  }

 
  findCnpj(){
    let {cnpj} = this.registerForm.value
    cnpj = cnpj.replace(/[^\d]+/g, '');

    this.utilsService.consultarCNPJ(cnpj).then(({ response }: any)=> {
      const { nome } = response;
  
      this.registerForm.get('nomeEmpresa')?.setValue(nome)

    })
  }

  async validUser(email: String){
    this.registered = false
    this.isCNPJ = false
    const [,dominio] = email.split("@")

    const { retorno, CNPJ }: any = await this.utilsService.isRegisteredCustomer(email)
    const { response } = await this.utilsService.isExistingEmail(email)

    //Valida se ja existe o e-mail cadastrado
    if (response) {
      this.notification.warning({
        message: `Email já possui cadastro;`,
        duration: 3000,
        orientation: PoToasterOrientation.Top
      });
      this.registered = true;
    } 
    //Se não tiver cadastrado, valida se é @hi-mix.com.br
    else if (dominio.toUpperCase().includes('HI-MIX')) {
        this.registered = true;
        this.isRequired = true;
        this.isCNPJ = true;
        this.registerForm.get('cnpj')?.setValue("14785345000102");
        this.registerForm.get('nomeEmpresa')?.setValue("HI-MIX ELETRONICOS S/A"); 
    } 
    //Se não, cadastra como um externo
    else {
      this.isCNPJ = true;
      this.registered = true;
      this.isRequired = true;
    }
  }
}


