import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {PoNavbarIconAction} from '@po-ui/ng-components'
import { PoNavbarLiterals } from '@po-ui/ng-components/lib/components/po-navbar/interfaces/po-navbar-literals.interface';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationService } from 'src/app/services/i18n/translation.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  literals: any = {};
  title!: string;
  profile: any;
  profileActions: any;

  constructor(
    private router: Router,
    public auth: AuthService,
    private translationService: TranslationService
  ) { 
  
  }

  ngOnInit(): void {
    //this.title = `Hi-Mix | ${this.auth.getUserData('NOME_EMPRESA')} | ${this.auth.getUserData('NOME').toLowerCase()}`;
    this.title = `Portal do Cliente`;
    this.loadLiterals();

    this.profile = {
      subtitle: this.auth.getUserData('EMAIL').toLowerCase(),
      title: this.auth.getUserData('NOME').toLowerCase()
    };

    this.profileActions = [
      { 
        icon: 'po-icon-world', 
        label: `Idioma/Language`,  
        separator: true ,
        action: (item:string) => this.changeLanguage(this.translationService.currentLanguage),
      },
      { 
        icon: 'po-icon-exit', 
        label: 'Sair', 
        type: 'danger', 
        separator: true ,
        action: this.logoutHeader.bind(this),
      }
    ];

  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
    });
  }

  changeLanguage(language: string){
    language = language != 'pt' ? 'pt-BR' : 'en-US';
    this.translationService.changeLanguage(language);
    this.loadLiterals();
    this.router.navigate(['/']);
  }

  reedirect(path: string) {
    this.router.navigate([path])
  }

  logoutHeader(){
    this.auth.logout()
    this.router.navigate(['/login'])
  }
}
