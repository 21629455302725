import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { PoModule,PoFieldModule  } from '@po-ui/ng-components';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { PoI18nModule } from '@po-ui/ng-components'; 
import { PoI18nConfig } from '@po-ui/ng-components';
import { PoBreadcrumbModule } from '@po-ui/ng-components';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConsultarComponent } from './pages/proposta-comercial/consultar/consultar.component';
import { NovaSolicitacaoComponent } from './pages/proposta-comercial/nova-solicitacao/nova-solicitacao.component';
import { HomeComponent } from './pages/default-system/home/home.component';
import { HeaderComponent } from './Components/header/header.component'
import { PaginatorComponent } from './Components/paginator/paginator.component';
import { EnviarComponent } from './Components/enviar/enviar.component';
import { PropostaComponent } from './Components/Filhos/proposta/proposta.component';
import { NegociacaoComponent } from './Components/Filhos/negociacao/negociacao.component';
import { DocumentosComponent } from './Components/Filhos/documentos/documentos.component';
import { LoadingComponent } from './Components/loading/loading.component';
import { LoginComponent } from './pages/default-system/login/login.component';
import { RegisterComponent } from './pages/default-system/register/register.component';
import { ChangePasswordComponent } from './pages/default-system/change-password/change-password.component';
import { SecondAuthenticationComponent } from './pages/default-system/second-authentication/second-authentication.component';
import { AprovacaoComponent } from './pages/proposta-comercial/aprovacao/aprovacao.component';
import { MenuComponent } from './Components/menu/menu.component';
import { ConsultaDocumentosComponent } from './pages/certificado-origem/consulta-documentos/consulta-documentos.component';
import { VisualizarSolicitacoesComponent } from './pages/visualizar-solicitacoes/visualizar-solicitacoes.component';
import { ListDocumentsComponent } from './pages/certificado-origem/list-documents/list-documents.component';
import { DocumentsComponent } from './pages/certificado-origem/documents/documents.component';
import { ListInvoicePackingComponent } from './pages/comex/list-invoice-packing/list-invoice-packing.component';
import { EditInvoicePackingComponent } from './pages/comex/edit-invoice-packing/edit-invoice-packing.component';
import { generalEn } from './services/i18n/general-en';
import { generalPt } from './services/i18n/general-pt';


const i18nConfig: PoI18nConfig = {
  default: {
    language: 'pt-BR',
    context: 'general',
    cache: true
  },
  contexts: {
    general: {
      'pt-BR': generalPt,
      'en-US': generalEn
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ConsultarComponent,
    NovaSolicitacaoComponent,
    HomeComponent,
    HeaderComponent,
    PaginatorComponent,
    EnviarComponent,
    PropostaComponent,
    NegociacaoComponent,
    DocumentosComponent,
    LoadingComponent,
    LoginComponent,
    RegisterComponent,
    ChangePasswordComponent,
    SecondAuthenticationComponent,
    AprovacaoComponent,
    MenuComponent,
    ConsultaDocumentosComponent,
    VisualizarSolicitacoesComponent,
    ListDocumentsComponent,
    DocumentsComponent,
    ListInvoicePackingComponent,
    EditInvoicePackingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClickOutsideModule,
    AppRoutingModule,
    PoModule,
    PoFieldModule,
    PoBreadcrumbModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PoTemplatesModule,
    HttpClientModule,
    PoI18nModule.config(i18nConfig)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

