import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PoTableColumn } from '@po-ui/ng-components';
import { ConsultaDocumentosService } from './consulta-documentos.service'
import { TranslationService } from 'src/app/services/i18n/translation.service';

@Component({
  selector: 'app-consulta-documentos',
  templateUrl: './consulta-documentos.component.html',
  styleUrls: ['./consulta-documentos.component.css']
})
export class ConsultaDocumentosComponent implements OnInit {

  constructor(
    private service: ConsultaDocumentosService,
    private route: Router,
    private translationService: TranslationService
  ){}

  literals: any = {};
  gridItems: Array<any> = []
  gridColumns:Array<PoTableColumn> = []
  
  async ngOnInit() {
    const {response}:any = await this.service.getDatas()
    this.gridItems = response
    
    this.loadLiterals();
  }

  loadLiterals(){
    this.translationService.loadLiterals('general').subscribe(literals => {
      this.literals = literals;
      this.loadValues();
    });
  }
  
  loadValues(){
    this.gridColumns = [
      { label: this.literals.solicitationNumber, property: 'PROCESS_ID'},
      { label: this.literals.openingDate, property: 'DATA'},
      { label: this.literals.approvalDeadline, property: 'DATA_APROVA'},
      { label: 'DOCUMENT_ID', property: 'DOCUMENT_ID',visible:false},
      { 
        label: this.literals.documentValidation,
        property: 'status',
        type:'icon',
        icons: [
          { 
            value: 'onApproval', 
            icon: 'po-icon-eye', 
            tooltip: 'Aprovar Solicitação',  
            action: this.extras.bind(this),
          },{ 
            value: 'disabled', 
            icon: 'po-icon-eye', 
            tooltip: 'Visualizar Solicitação',  
            action: this.extras.bind(this),
          }
      ]
      },
      { 
        label: this.literals.status,
        property: 'NUM_ATIV' ,
        width: "30%",
        type:"label",
        labels: [
          { value: 77, color: 'color-01', label: this.literals.validateProductDeclaration },
          { value: 160, color: 'color-11', label: this.literals.approveDraft },
          { value: 66, color: 'color-10', label: this.literals.requestCompleted },
          { value: 51, color: 'color-08', label: this.literals.inProgress },
          { value: 0,  color: 'color-12', label: this.literals.inApprovalByComex },
        ]
      }
    ]
  }

  extras(row: any) {
    const decision = (row?.status == 'onApproval' || row?.status == 'toCorrect' )  ? true : false;
      this.route.navigate(
        ["/documents",row?.DOCUMENT_ID  ],
        { 
          queryParams: { 
            edit: decision,
            activity:row?.NUM_ATIV
          } 
        })
    
  }

}
