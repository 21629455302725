<!-- <div class="po-row">
  <div style="margin-top: 25px;margin-bottom: 25px;margin-left: 25px;align-items: center  ;">
    
  </div>
</div> -->
<po-widget class="m-b-1">
  <div class="po-row">
    <div class="po-col-12 po-md-6">
      <span class="">
        
      </span>
    </div>
    <div class="po-col-12 po-md-6 po-text-right">
      <span class="m-r-1 blue cursor-pointer" (click)="onResetFilters()">
        {{this.literals.clearFilters}}
      </span>
    </div>
  </div>
  
  
  
    <div  class="po-row">
      <div [ngClass]="isRepresentante ? 'po-xl-3 po-lg-3 po-md-3 po-sm-5' : 'po-xl-4 po-lg-4 po-md-4 po-sm-6'">
        <po-multiselect
          [p-sort]="true" 
          [p-hide-search]='false' 
          [p-hide-select-all]='true' 
          [p-options]="filterOptions.NUMPROPOSTA"
          p-label={{this.literals.commercialProposalNumber}}
          [(ngModel)]="filters.NUMPROPOSTA"
          [p-disabled]="disableFilters"
          (p-change)="onChangeFilters()"
          p-placeholder={{this.literals.selectItems}}
          p-placeholder-search={{this.literals.search}}
        >
        </po-multiselect>
      </div>
      <div [ngClass]="isRepresentante ? 'po-xl-3 po-lg-3 po-md-3 po-sm-5' : 'po-xl-4 po-lg-4 po-md-4 po-sm-6'">
        <po-multiselect
          [p-sort]="true" 
          [p-hide-search]='false' 
          [p-hide-select-all]='true' 
          [p-options]="filterOptions.RFQ_REVISAO"
          p-label={{this.literals.revisionNumber}}
          [(ngModel)]="filters.RFQ_REVISAO"
          [p-disabled]="disableFilters"
          (p-change)="onChangeFilters()"
          p-placeholder={{this.literals.selectItems}}
          p-placeholder-search={{this.literals.search}}
        >
        </po-multiselect>
      </div>
      <div [ngClass]="isRepresentante ? 'po-xl-3 po-lg-3 po-md-3 po-sm-5' : 'po-xl-4 po-lg-4 po-md-4 po-sm-6'">
        <po-multiselect
          [p-sort]="true" 
          [p-hide-search]='false' 
          [p-hide-select-all]='true' 
          p-label={{this.literals.status}}
          [p-options]="filterOptions.NUM_SEQ_ESTADO"
          [(ngModel)]="filters.NUM_SEQ_ESTADO"
          [p-disabled]="disableFilters"
          (clickOutside)="onChangeFilters()"
          p-placeholder={{this.literals.selectItems}}
          p-placeholder-search={{this.literals.search}}
        >
        </po-multiselect>
      </div>
      <div [ngClass]="isRepresentante ? 'po-xl-3 po-lg-3 po-md-3 po-sm-5' : 'po-xl-4 po-lg-4 po-md-4 po-sm-6'" *ngIf="isRepresentante">
        <po-multiselect
          p-label={{this.literals.client}}
          [p-options]="filterOptions.NOME_EMPRESA"
          [p-hide-select-all]='true' 
          [(ngModel)]="filters.NOME_EMPRESA"
          [p-disabled]="disableFilters"
          (p-change)="onChangeFilters()"
          p-placeholder={{this.literals.selectItems}}
          p-placeholder-search={{this.literals.search}}
        >
        </po-multiselect>
      </div>
    </div>
  </po-widget>
  
  <po-widget>
    <po-table style="width: 100%;" 
    [p-columns]="gridColumns" 
    [p-items]="gridItems"
    [p-loading]="refreshingGrid"
    [p-sort]="true"
    [p-hide-columns-manager]="true"
    
    >
  </po-table>
  <div class="po-row po-xl-12 po-lg-12 po-md-12 po-sm-12" style="display: flex;align-items: center;justify-content: space-between;">
    <app-paginator 
      class="space-between" 
      [total-hits]="gridTotalHits"
      [current-page]="gridCurrentPage"
      [total-grid]="sizePage"
      (on-change-page)="onChangePage($event)"

    ></app-paginator>

    <!-- <po-select style="display:none"
      [ngModel]="sizePage"
      [p-options]="options"
      p-show-required="boolean" 
      (clickOutside)="handlerSizePage($event)"
      [(ngModel)]="sizePage"
    >
  </po-select> -->
  
  </div>
 
  </po-widget>
  
